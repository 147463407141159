import { BaseError, CacheMap, Promised, SafeNumber } from '@zimbro-app/util';
import { hmac } from '#util';
import {
  ApiClient,
  ApiClientConfig,
  ApiReply,
  AuthSuccessTokenReply,
  Balance,
  Ledger,
  Payment,
  PaymentReply,
  Vendor,
  WsMethodParams,
  WsReply,
  WsSubscription,
  BalanceReply,
  BalanceReplyTokenBalance,
  TokenBalance,
  Tx,
  TxReplyItem,
  TxReply,
  VendorReply,
  ServerReply,
  PathReply,
} from './types';

export enum Method {
  get = 'get',
  put = 'put',
  post = 'post',
  patch = 'patch',
  delete = 'delete',
}

export enum Path {
  signIn = 'signIn',
  signUp = 'signUp',
  signOut = 'signOut',
  checkIn = 'checkIn',
  ping = 'ping',
  pingBackend = 'pingBackend',
  balance = 'balance',
  transactions = 'transactions',
  payment = 'payment',
  paymentId = 'paymentId',
  paymentMethod = 'paymentMethod',
  paymentCancel = 'paymentCancel',
  paymentRollback = 'paymentRollback',
  vendors = 'vendors',
  vendor = 'vendor',
  vendorId = 'vendorId',
  tokens = 'tokens',
}

export const paths: { [path in Path]: string } = {
  [Path.signIn]: 'sign-in',
  [Path.signUp]: 'sign-up',
  [Path.signOut]: 'sign-out',
  [Path.checkIn]: 'check-in',
  [Path.ping]: 'ping',
  [Path.pingBackend]: 'ping/backend',
  [Path.balance]: 'balance',
  [Path.transactions]: 'transactions',
  [Path.payment]: 'payment',
  [Path.paymentId]: 'payment/:paymentId',
  [Path.paymentMethod]: 'payment/:paymentId/method',
  [Path.paymentCancel]: 'payment/:paymentId/cancel',
  [Path.paymentRollback]: 'payment/:paymentId/rollback',
  [Path.vendors]: 'vendors',
  [Path.vendor]: 'vendor',
  [Path.vendorId]: 'vendor/:vendorId',
  [Path.tokens]: 'tokens',
};

const requiresAuth = new Set([
  Path.checkIn,
  Path.signOut,
  Path.balance,
  Path.transactions,
  Path.vendor,
  Path.vendorId,
  Path.vendors,
]);

export enum WsMethod {
  balance = 'balance',
  ping = 'ping',
  tx = 'tx',
}

export class ApiError extends BaseError {
  statusCode?: number;
  error?: string;
}

export class WsApiError extends ApiError {}
export class AuthenticationError extends ApiError {}
export class AuthorizationError extends ApiError {}

const Parse = {
  tokenBalance(b: BalanceReplyTokenBalance): TokenBalance {
    return {
      ...b,
      balance: SafeNumber.fromJson(b.balance),
      tokenRate: SafeNumber.fromJson(b.tokenRate),
      updatedAt: new Date(b.updatedAt),
      rateUpdatedAt: new Date(b.rateUpdatedAt),
    };
  },
  balance(reply: BalanceReply): Balance {
    return {
      ...reply,
      updatedAt: new Date(reply.updatedAt),
      totalBalance: SafeNumber.fromJson(reply.totalBalance),
      tokenBalance: reply.tokenBalance.map(Parse.tokenBalance),
    };
  },
  transaction(t: TxReplyItem): Tx {
    return {
      ...t,
      timestamp: new Date(t.timestamp),
      amount: SafeNumber.fromJson(t.amount),
      serviceFee: SafeNumber.fromJson(t.serviceFee),
      networkFee: SafeNumber.fromJson(t.networkFee),
    };
  },
  transactions(reply: TxReply): Ledger {
    return reply.map(Parse.transaction);
  },
  vendor(reply: VendorReply): Vendor {
    return {
      ...reply,
      createdAt: new Date(reply.createdAt),
      updatedAt: new Date(reply.updatedAt),
    };
  },
  vendors(reply: VendorReply[]): Vendor[] {
    return reply.map(Parse.vendor);
  },
  payment(reply: PaymentReply): Payment {
    return {
      ...reply,
      total: SafeNumber.fromJson(reply.total),
      paymentAmount:
        reply.paymentAmount && SafeNumber.fromJson(reply.paymentAmount),
      createdAt: new Date(reply.createdAt),
      updatedAt: new Date(reply.updatedAt),
      expiresAt: new Date(reply.expiresAt),
    };
  },
};

export const Parsers: {
  [path in Path]?: (reply: ServerReply[path]) => PathReply[path];
} = {
  [Path.balance]: Parse.balance,
  [Path.transactions]: Parse.transactions,
  [Path.payment]: Parse.payment,
  [Path.paymentId]: Parse.payment,
  [Path.paymentMethod]: Parse.payment,
  [Path.paymentCancel]: Parse.payment,
  [Path.paymentRollback]: Parse.payment,
  [Path.vendors]: Parse.vendors,
  [Path.vendor]: Parse.vendor,
  [Path.vendorId]: Parse.vendor,
};

function Client({
  baseUrl = '',
  apiVersion = 'v1',
  wsApiVersion = 'v1',
  resolvedWsMessageQueueLimit = 100,
}: ApiClientConfig = {}): ApiClient {
  const apiUrl = `${baseUrl}/api/${apiVersion}`;
  const wsBaseUrl = baseUrl.toString().replace(/http(s)?/, 'ws$1');
  const wsApiUrl = `${wsBaseUrl}/ws/${wsApiVersion}`;

  let csrfToken: string | undefined;

  const request = async <Req extends Record<string, any>, Res>(
    {
      path,
      method,
      params,
      doAuth,
    }: {
      path: string;
      method: Method;
      params?: Req;
      doAuth?: boolean;
    },
    { signingKey, ...req }: RequestInit & { signingKey?: string } = {}, // TODO: improve the API
  ) => {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      'credentials': 'include',
    };

    if (doAuth && typeof csrfToken === 'string') {
      headers['CSRF-Token'] = csrfToken;
    }

    const querystring =
      params && method === Method.get
        ? `?${new URLSearchParams(params).toString()}`
        : '';

    const body =
      params && method !== Method.get ? JSON.stringify(params) : null;

    if (body && signingKey) {
      headers.signature = await hmac('SHA-256', body, signingKey);
    }

    const response = await fetch(`${apiUrl}/${path}${querystring}`, {
      method: method.toUpperCase(),
      headers,
      credentials: 'include',
      body,
      ...req,
    });

    if (!response.ok) {
      let body = {};
      try {
        body = (await response.json()) as object;
      } catch (e) {
        console.error('Failed to parse error body', e);
      } finally {
        if (response.status === 401) {
          throw new AuthenticationError(response.statusText, body);
        } else if (response.status === 403) {
          throw new AuthorizationError(response.statusText, body);
        } else {
          throw new ApiError(response.statusText, body);
        }
      }
    }

    const { data, success, error } = (await response.json()) as ApiReply<Res>;

    if (!success) {
      if (!error) {
        throw new ApiError('Unknown error', {
          data,
          success,
          error,
          path,
          method,
          req,
        });
      }
      throw new ApiError(error as string);
    }

    if (!data || Array.isArray(data) || typeof data !== 'object') {
      return data;
    }

    const { csrfToken: _csrfToken, ...payload } =
      data as unknown as AuthSuccessTokenReply;

    if (_csrfToken) {
      csrfToken = _csrfToken;
    }

    return payload;
  };

  let websocket: WebSocket | undefined;

  const listeners = new CacheMap<Promise<WsSubscription<any>>>();
  const wsConnect = async () => {
    if (websocket) {
      return websocket;
    }
    try {
      const connection = Promised<WebSocket>();
      const socket = new WebSocket(wsApiUrl);
      socket.onopen = () => connection.resolve(socket);
      socket.onclose = event => {
        console.log('Websocket closed:', event);
        websocket = undefined;
      };

      socket.onerror = async error => {
        console.log('Websocket error:', error);
        connection.reject(error);
        for (const { path, value } of listeners.paths()) {
          try {
            const subsciption = await value;
            subsciption.cancel(); // it also deletes the path internally
          } catch (error) {
            console.error('Failed to cancel subscription @', path);
            listeners.delete(...path);
          }
        }
        websocket = undefined;
      };

      socket.onmessage = async ({ data }) => {
        let json: string;
        if (typeof data === 'string') {
          json = data;
        } else if (data instanceof Blob) {
          json = await data.text();
        } else if (data instanceof ArrayBuffer) {
          const blob = new Blob([data], { type: 'text/plain; charset=utf-8' });
          json = await blob.text();
        } else {
          console.error(
            'Unrecognized message type received:',
            typeof data === 'object' ? data.constructor.name : typeof data,
            data,
          );
          return;
        }
        const message = JSON.parse(json) as WsReply<any>;
        const { method, params } = message || {};
        const query = querystring(params || {});
        const subscription = await listeners.get('ws', method, query);
        if (!subscription) {
          console.warn('No listener found for message:', 'ws', method, query, {
            message,
          });
          return;
        }
        subscription.next = message as unknown as Promise<WsReply<any>>; // TODO: doesn't look right
      };

      return (websocket = await connection);
    } catch (cause) {
      throw new ApiError('Failed to establish websocket connection', {
        cause,
      });
    }
  };

  const querystring = (obj: object) =>
    Object.entries(obj)
      .sort(([a], [b]) => (a < b ? -1 : 1))
      .map(([k, v]) => `${k}=${v}`)
      .join('&');

  const wsHandler = new Proxy(
    {},
    {
      get(_, method: WsMethod) {
        if (!(method in WsMethod)) {
          throw new WsApiError(`Unsupported ws method: ${method}`);
        }

        return async <T extends WsMethod>(params: WsMethodParams[T]) => {
          const query = querystring(params || {});
          try {
            const listener = await listeners.get('ws', method, query);
            if (listener) {
              console.log('reusing listener', 'ws', method, query);
            }
            return (
              listener ||
              (await listeners.touch('ws', method, query, () =>
                (async () => {
                  let current = Promised<WsReply<T>>();
                  const queue: Promised<WsReply<T>>[] = [current];
                  const cancel = () => {
                    console.log('deleting listener', method, query);
                    current.cancel();
                    listeners.delete('ws', method, query);
                    if (!listeners.paths('ws').length && websocket) {
                      websocket.close();
                    }
                  };

                  try {
                    const socket = await wsConnect();
                    socket.send(JSON.stringify({ method, params }));
                  } catch (error) {
                    console.error('Subscription error', error);
                    current.reject(error);
                    cancel();
                  } finally {
                    return {
                      get next(): Promised<WsReply<T>> {
                        return queue.shift() || current;
                      },
                      set next(reply: WsReply<T>) {
                        current.resolve(reply);
                        if (queue.length > resolvedWsMessageQueueLimit) {
                          throw new WsApiError(
                            `Resolved posts queue overflow, please check consumer code`,
                          );
                        }
                        current = Promised<WsReply<T>>();
                        queue.push(current);
                      },
                      cancel,
                    };
                  }
                })(),
              ))
            );
          } catch (error) {
            console.error('Failed to establish ws connection:', error);
            throw error;
          }
        };
      },
    },
  );

  const client: ApiClient = new Proxy(
    {},
    {
      get(_, method) {
        if (method === 'ws') {
          return wsHandler;
        }

        if (!(method in Method)) {
          throw new ApiError(`Unsupported HTTP method: "${method as string}"`);
        }

        method = method as Method;

        return new Proxy(
          {},
          {
            get(_, path: Path) {
              if (!(path in Path)) {
                throw new ApiError(`Unsupported path: "${path as string}"`);
              }

              const fullPath = paths[path as Path] || path;

              return async <Req extends Record<string, any>, Res>(
                params?: Req,
                req?: RequestInit & { signingKey?: string },
              ) => {
                const dirs = [];
                for (const dir of fullPath.split('/')) {
                  if (dir.startsWith(':')) {
                    const id = dir.slice(1);
                    if (params && id in params) {
                      dirs.push(params[id]);
                      delete params[id];
                    } else {
                      throw new ApiError('Missing resource id', {
                        dir,
                        fullPath,
                      });
                    }
                  } else {
                    dirs.push(dir);
                  }
                }

                if (path === Path.signOut) {
                  csrfToken = undefined;
                }

                const result = (await request<Req, Res>(
                  {
                    method: method as Method,
                    path: dirs.join('/'),
                    params,
                    doAuth: requiresAuth.has(path as Path),
                  },
                  req,
                )) as ServerReply[typeof path];

                return (
                  Parsers[path]
                    ? // @ts-expect-error
                      Parsers[path](result)
                    : result
                ) as PathReply[typeof path];
              };
            },
          },
        );
      },
    },
  ) as ApiClient;

  return client;
}

const API_URL = process.env.API_URL;
const API_VERSION = process.env.API_VERSION;
const WS_API_VERSION = process.env.WS_API_VERSION;

export default Client({
  baseUrl: API_URL,
  apiVersion: API_VERSION,
  wsApiVersion: WS_API_VERSION,
});
