export const formatSum = (
  x: number,
  {
    minDecimals = 2,
    decimals = 2,
  }: {
    minDecimals?: number;
    decimals?: number;
  } = {},
) =>
  Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: decimals,
  }).format(x);

export const formatUsd = (x: number) =>
  Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(x);
