// https://emailregex.com
const pattern =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (s: string): boolean => {
  if (!s || typeof s !== 'string') {
    return false;
  }

  const parts = s.split('@');

  if (parts.length !== 2) {
    return false;
  }

  const [account, address] = parts;

  if (account.length > 64) {
    return false;
  } else if (address.length > 255) {
    return false;
  }

  for (const part of address.split('.')) {
    if (part.length > 63) {
      return false;
    }
  }

  return pattern.test(s);
};
