import api from '#api';
import { BaseError } from '@zimbro-app/util';
import { Payment } from 'api/types';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { usePaymentContext } from '../Context';
import { getQrCodeUrl } from '#util';

type PaymentDetailsContext = {
  isLoading: boolean;
  payment: Payment;
  paymentUrl: string;
  error?: Error;
  cancel: () => void;
  rollback: () => void;
};

const PaymentDetailsContext = createContext<PaymentDetailsContext>({
  isLoading: true,
  payment: {} as Payment,
  paymentUrl: '',
  cancel() {},
  rollback() {},
});

export const usePaymentDetailsContext = () => useContext(PaymentDetailsContext);

class PaymentDetailsError extends BaseError {}

export const PaymentDetailsContextProvider: FC<
  PropsWithChildren<{ payment: Payment }>
> = ({ children, payment }) => {
  const { updatePayment } = usePaymentContext();
  const [isLoading, _setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const [paymentUrl, setPaymentUrl] = useState<string>('');

  useEffect(() => {
    if (
      !payment ||
      !payment.paymentAmount ||
      !payment.wallet ||
      !payment.paymentToken ||
      !payment.paymentChain
    ) {
      setError(
        new PaymentDetailsError('Invalid payment details', {
          paymentAmount: payment?.paymentAmount,
          paymentChain: payment?.paymentChain,
          wallet: payment?.wallet,
        }),
      );
    } else {
      setPaymentUrl(getQrCodeUrl(payment));
    }
  }, [
    payment && payment.paymentAmount && payment.paymentAmount.format,
    payment && payment.paymentToken,
    payment && payment.paymentChain,
    payment && payment.wallet,
    payment && payment.paymentTokenContract,
  ]);

  const rollback = async () => {
    if (payment.expiresAt.getTime() < Date.now()) {
      return setError(new PaymentDetailsError('Payment expired'));
    }
    try {
      const reply = await api.patch.paymentRollback({
        paymentId: payment.paymentId,
        orderId: payment.orderId,
      });
      if (!reply) {
        throw 'Invalid payment';
      }
      updatePayment(reply);
    } catch (cause) {
      const error = new PaymentDetailsError('Failed to change payment status', {
        cause,
      });
      setError(error);
    }
  };

  const cancel = async () => {
    if (payment.expiresAt.getTime() < Date.now()) {
      return setError(new PaymentDetailsError('Payment expired'));
    }
    try {
      const reply = await api.patch.paymentCancel({
        paymentId: payment.paymentId,
        orderId: payment.orderId,
      });
      if (!reply) {
        throw 'Invalid payment';
      }
      updatePayment(reply);
    } catch (cause) {
      const error = new PaymentDetailsError('Failed to cancel payment', {
        cause,
      });
      setError(error);
    }
  };

  const value = {
    error,
    payment,
    isLoading,
    rollback,
    cancel,
    paymentUrl,
  };

  return (
    <PaymentDetailsContext.Provider value={value}>
      {children}
    </PaymentDetailsContext.Provider>
  );
};
