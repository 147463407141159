// https://stackoverflow.com/a/3809435
const pattern =
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

export const isValidUrl = (s: string): boolean => {
  if (!s || typeof s !== 'string') {
    return false;
  }

  return pattern.test(s);
};
