export const hmac = async (
  algorithm: 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512',
  payload: string,
  key: string,
) =>
  (async (encoder: TextEncoder) =>
    Array.from(
      new Uint8Array(
        await window.crypto.subtle.sign(
          'HMAC',
          await window.crypto.subtle.importKey(
            'raw',
            encoder.encode(key),
            {
              name: 'HMAC',
              hash: { name: algorithm },
            },
            false,
            ['sign'],
          ),
          encoder.encode(payload),
        ),
      ),
      (byte: number) => byte.toString(16).padStart(2, '0'),
    ).join(''))(new TextEncoder());
