import { PropsWithChildren, createContext, useContext } from 'react';

import { Navigate } from 'react-router-dom';
import { Account, useAppContext } from '#components/App/Context';

type DashboardContext = {
  account: Account;
};

const DashboardContext = createContext<DashboardContext>({
  account: { id: '', name: '', email: '', type: 'personal' },
});

export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardContextProvider = ({ children }: PropsWithChildren) => {
  const { account } = useAppContext();

  return account && account.id ? (
    <DashboardContext.Provider value={{ account }}>
      {children}
    </DashboardContext.Provider>
  ) : (
    <Navigate to='/' />
  );
};
