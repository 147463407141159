enum At {
  end = 'end',
  start = 'start',
  middle = 'middle',
}

export type EllipsizeOpts = {
  at?: 'end' | 'start' | 'middle';
  ellipsis?: string;
};

export const ellipsize = Object.assign(
  (
    x: string,
    limit: number,
    { at = 'end', ellipsis = '…' }: EllipsizeOpts = {},
  ) =>
    x.length <= limit
      ? x
      : limit < 1
        ? ''
        : ellipsis.length >= limit
          ? ellipsis.slice(0, limit)
          : (at === At.start
              ? ''
              : x.slice(
                  0,
                  at === At.middle
                    ? Math.ceil((limit - ellipsis.length) / 2)
                    : limit - ellipsis.length,
                )) +
            ellipsis +
            (at === At.end
              ? ''
              : x.slice(
                  x.length -
                    (at === At.middle
                      ? Math.floor((limit - ellipsis.length) / 2)
                      : limit - ellipsis.length),
                  x.length,
                )),
  {
    at: At,
    start: (x: string, limit: number, opts?: Omit<EllipsizeOpts, 'at'>) =>
      ellipsize(x, limit, { at: At.start, ...opts }),

    mid: (x: string, limit: number, opts?: Omit<EllipsizeOpts, 'at'>) =>
      ellipsize(x, limit, { at: At.middle, ...opts }),

    end: (x: string, limit: number, opts?: Omit<EllipsizeOpts, 'at'>) =>
      ellipsize(x, limit, { at: At.end, ...opts }),
  },
);
