import { useState } from 'react';
import cx from 'classnames';
import PaymentList from './List';
import PaymentForm from './Create';
import { useNavigate } from 'react-router-dom';

enum View {
  List,
  Form,
}

export default () => {
  const navigate = useNavigate();
  const [view, setView] = useState<View>(View.List);

  const backToList = () => {
    setView(View.List);
  };

  const create = () => {
    setView(View.Form);
  };

  return (
    <div className={cx('uk-section-small')}>
      {view === View.List && <PaymentList onCreateClick={create} />}
      {view === View.Form && (
        <PaymentForm
          onSuccess={(paymentId: string) => {
            navigate('/payment/' + paymentId);
          }}
          onClose={backToList}
        />
      )}
    </div>
  );
};
