import cx from 'classnames';
import { FC } from 'react';

const PaymentLoading: FC<{ ratio?: number }> = ({ ratio = 4 }) => (
  <div className={cx('uk-flex', 'uk-flex-center', 'uk-margin-medium-top')}>
    <div className={cx('zimbro-spinner')} data-uk-spinner={`ratio: ${ratio}`} />
  </div>
);

export default PaymentLoading;
