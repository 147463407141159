import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSignup } from './Context';
import { ChangeEvent } from 'react';

export const AccountTypeView = ({
  title,
  description,
  features,
  className,
}: {
  title: string;
  description: string;
  features: string[];
  className?: string;
}) => {
  const { data, handleChange } = useSignup();
  const isChecked = data.accountType === title;

  return (
    <div className={cx(className)}>
      <div
        className={cx(
          'uk-card',
          'uk-card-body',
          'uk-card-hover',
          'uk-border-rounded',
          'zimbro-account-type',
          {
            'uk-card-default': !isChecked,
            'uk-card-primary': isChecked,
          },
        )}
        onClick={() =>
          handleChange('accountType')({
            target: { value: title },
          } as ChangeEvent<HTMLInputElement>)
        }
        style={{ cursor: 'pointer', height: '100%' }}
      >
        <label className='uk-card-title uk-text-capitalize'>
          <input
            id={`sign-up-account-type-${title}`}
            className={cx('uk-radio', 'zimbro-radio')}
            type='radio'
            checked={isChecked}
            readOnly={true}
          />
          {title}
        </label>
        <p>{description}</p>
        <ul className='uk-list'>
          {features.map(feature => (
            <li key={`feature-${title}-${feature}`}>
              <span data-uk-icon='icon: check'></span>
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const AccountType = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className='uk-h2 uk-text-center'>{t('Account type')}</h2>
      <p className='uk-text-center uk-text-muted'>
        {t('Please choose your account type. It can be changed later.')}
      </p>
      <div
        data-uk-grid
        className={cx(
          'uk-grid-small',
          'uk-flex-center',
          'uk-grid-match',
          'uk-padding-small',
        )}
      >
        <AccountTypeView
          className={cx('uk-width-1-3@m', 'uk-width-1-1@s')}
          title={t('accountType.personal', 'personal')}
          description={t(
            'accountType.personal.description',
            'For individuals, entrepreneurs, artists, employees, etc.',
          )}
          features={[
            t('Send and receive payments'),
            t('Manage trusted contacts'),
            t('Accounting and payment history'),
          ]}
        />
        <AccountTypeView
          className={cx('uk-width-1-3@m', 'uk-width-1-1@s')}
          title={t('accountType.business', 'business')}
          description={t(
            'accountType.business.description',
            'For companies, funds, nonprofits, communities, etc.',
          )}
          features={[
            t('Role based access'),
            t('Global payrolls'),
            t('Tax reports and accounting'),
          ]}
        />
      </div>
    </div>
  );
};

export default AccountType;
