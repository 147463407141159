import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import api from '#api';
import { Vendor } from 'api/types';

type VendorListContext = {
  vendors: Vendor[];
  isLoading: boolean;
  error: Error | undefined;
};

const VendorListContext = createContext<VendorListContext>({
  isLoading: false,
  error: undefined,
  vendors: [],
});

export const useVendorListContext = () => useContext(VendorListContext);

export const VendorListContextProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [vendors, setVendors] = useState<Vendor[]>([]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const data = await api.get.vendors();
        setVendors(data);
        // setVendors([
        //   {
        //     vendorId: '0',
        //     vendorName: 'Vasya',
        //     origin: 'https://vasya.store',
        //     createdAt: new Date(),
        //     updatedAt: new Date(),
        //   },
        //   {
        //     vendorId: '1',
        //     vendorName: 'Petya Romashkin',
        //     origin: 'https://petr-romash.com',
        //     createdAt: new Date(),
        //     updatedAt: new Date(),
        //   },
        //   {
        //     vendorId: '2',
        //     vendorName: 'Alexandr Petrovich',
        //     origin: 'https://ululu.ru',
        //     createdAt: new Date(),
        //     updatedAt: new Date(),
        //   },
        //   {
        //     vendorId: '3',
        //     vendorName: 'The What Store',
        //     origin: 'https://the-what.store',
        //     createdAt: new Date(),
        //     updatedAt: new Date(),
        //   },
        //   {
        //     vendorId: '4',
        //     vendorName: 'Nano Tech',
        //     origin: 'https://nano.tech',
        //     createdAt: new Date(),
        //     updatedAt: new Date(),
        //   },
        //   {
        //     vendorId: '5',
        //     vendorName: 'Lorem Ipsum Store',
        //     origin: 'https://lorem-ipsum.nl',
        //     createdAt: new Date(),
        //     updatedAt: new Date(),
        //   },
        // ]);
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const value = { vendors, isLoading, error };

  return (
    <VendorListContext.Provider value={value}>
      {children}
    </VendorListContext.Provider>
  );
};
