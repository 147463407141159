import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import logo from 'assets/logo.svg';
// import logoFull from 'assets/logo-title.svg';

export default ({
  width,
  titled = false,
}: {
  width: number;
  titled?: boolean;
}) => (
  <NavLink
    to='/'
    className={cx('uk-logo', 'zimbro-logo', 'uk-flex', 'uk-flex-top')}
  >
    <img src={logo} width={width} height='auto' />
    {titled && <span className={cx('uk-text-secondary')}>Zimbro</span>}
  </NavLink>
);
