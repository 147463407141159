import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { coinIcons, formatSum } from '#util';
import Select, { components } from 'react-select';
import {
  ChainOption,
  PaymentMethodContextProvider,
  TokenOption,
  usePaymentMethodContext,
} from './Context';

import './index.less';
import { Payment } from 'api/types';
import { ErrorView, LoadIndicator } from '#components';

const PaymentMethod = () => {
  const { t } = useTranslation();
  const {
    payment,
    tokens,
    chains,
    setToken,
    setChain,
    token,
    chain,
    submit,
    isLoading,
    error,
  } = usePaymentMethodContext();

  return isLoading ? (
    <LoadIndicator />
  ) : error || !payment ? (
    <ErrorView error={error || new Error('Invalid payment')} />
  ) : (
    <div className={cx('uk-margin-medium-top')}>
      <div className={cx('uk-flex', 'uk-flex-between')}>
        <span className={cx('uk-text-large')}>
          {t('payment/method/total', 'Total')}
        </span>
        <span
          className={cx('uk-table-expand', 'uk-text-right', 'uk-text-lead')}
        >
          {`${formatSum(payment!.total.number, {
            decimals: payment!.total.decimals,
          })} ${payment!.token}`}
        </span>
      </div>
      <div className={cx('uk-margin-medium-top')}>
        <h2 className={cx('uk-card-title', 'uk-h3', 'uk-text-center')}>
          {t('payment/method/title', 'Choose Payment Method')}
        </h2>
        <p className={cx('uk-text-center', 'uk-text-small', 'uk-text-muted')}>
          {t(
            'payment.method.tooltip.text',
            `Please choose crypto currency and network you'd like to make the payment in.` +
              'The payment amount will be calculated and displayed on the next step.',
          )}
        </p>
      </div>
      <div>
        <Select
          inputId='paymentToken'
          placeholder={t('payment/method/select/token', 'Select Token...')}
          components={{
            Control: TokenControlView,
            Option: TokenOptionView,
          }}
          classNames={{
            control: () => cx('z-select'),
          }}
          value={token || null}
          onChange={o => setToken(o as TokenOption)}
          options={tokens}
        />
        <Select
          className={cx('uk-margin-top')}
          inputId='paymentChain'
          classNames={{
            control: () => cx('z-select'),
          }}
          isDisabled={!token}
          placeholder={t('payment/method/select/chain', 'Select Network...')}
          value={chain || null}
          onChange={o => setChain(o as ChainOption)}
          options={chains}
        />
      </div>
      <div
        className={cx(
          'uk-flex',
          'uk-flex-around',
          'uk-margin-medium-top',
          'uk-child-width-1-2',
        )}
      >
        <a
          href={payment!.onReturn}
          className={cx(
            'uk-button',
            'uk-button-secondary',
            'uk-border-pill',
            'uk-margin-small-right',
          )}
        >
          {t('payment/method/button/back', 'Back to Store')}
        </a>
        <a
          href='#'
          onClick={e => (e.preventDefault(), submit())}
          className={cx(
            'uk-button',
            'uk-button-primary',
            'uk-border-pill',
            'uk-margin-small-left',
            {
              'zimbro-button-disabled': !(token && chain),
              'uk-text-muted': !(token && chain),
              'uk-disabled': !(token && chain),
            },
          )}
        >
          {t('Proceed')}
        </a>
      </div>
    </div>
  );
};

const TokenControlView: typeof components.Control<
  TokenOption,
  false,
  { options: [] }
> = ({ children, ...props }) => {
  const token = props.hasValue ? props.getValue()[0].value : undefined;
  return (
    <components.Control {...props}>
      {token && (
        <img
          className={cx('uk-margin-small-left')}
          src={coinIcons[token.toLowerCase()]}
          width='24'
          height='auto'
          alt={token}
        />
      )}
      {children}
    </components.Control>
  );
};

const TokenOptionView: typeof components.Option<
  TokenOption,
  false,
  { options: [] }
> = ({ children, ...props }) => {
  const token = props.data.value;
  return (
    <components.Option {...props}>
      {token && (
        <img
          className={cx('uk-margin-small-right')}
          src={coinIcons[token.toLowerCase()]}
          width='24'
          height='auto'
          alt={token}
        />
      )}
      {children}
    </components.Option>
  );
};

export default (props: { payment: Payment }) => (
  <PaymentMethodContextProvider {...props}>
    <PaymentMethod />
  </PaymentMethodContextProvider>
);
