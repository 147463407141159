import cx from 'classnames';
import { FC, PropsWithChildren } from 'react';

export type PageProps = {
  title: string;
  className?: string;
  sectionClassName?: string;
};

export const Page: FC<PropsWithChildren<PageProps>> = ({
  children,
  title,
  className,
  sectionClassName = 'uk-section-small',
}) => {
  return (
    <div className={cx('uk-container', 'uk-padding-large', className)}>
      <h1 className='uk-h1'>{title}</h1>
      <div className={cx(sectionClassName)}>{children}</div>
    </div>
  );
};

export default Page;
