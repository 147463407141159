import { useAppContext } from '#components/App/Context';
import {
  FC,
  FormEvent,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import api, { ApiError } from '#api';
import { hashPassword } from '#util';

interface SignInContext {
  login?: string;
  password?: string;
  loginError?: Error;
  passwordError?: Error;
  authError?: ApiError;
  setLogin: (_: string) => void;
  setPassword: (_: string) => void;
  handleSubmit: (event: FormEvent) => Promise<void>;
  reset: () => void;
}

const SignInContext = createContext<SignInContext>({
  setLogin: () => {},
  setPassword: () => {},
  handleSubmit: async (_: FormEvent) => {},
  reset: () => {},
});

export const SignInProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const { setAccount, setRoute } = useAppContext();

  const [login, setLogin] = useState<string | undefined>('');
  const [password, setPassword] = useState<string | undefined>('');
  const [loginError, setLoginError] = useState<Error | undefined>();
  const [passwordError, setPasswordError] = useState<Error | undefined>();
  const [authError, setAuthError] = useState<ApiError | undefined>();
  const [signingIn, setSigningIn] = useState<boolean>(false);

  const reset = () => {
    setLoginError(undefined);
    setPasswordError(undefined);
    setLogin('');
    setPassword('');
    setAuthError(undefined);
  };

  const handleSubmit = async () => {
    if (signingIn) {
      return;
    }
    setAuthError(undefined);
    if (!login) {
      setLoginError(
        new Error(
          t('signInForm.loginError', 'Please enter valid name or email'),
        ),
      );
      return;
    }
    if (!password) {
      setPasswordError(
        new Error(
          t('signInForm.loginError', 'Please enter valid name or email'),
        ),
      );
      return;
    }

    try {
      setSigningIn(true);
      const { accountId, accountName, accountEmail, accountType } =
        await api.post.signIn({
          nameOrEmail: login,
          password: hashPassword(password),
        });

      setAccount({
        id: accountId,
        name: accountName,
        email: accountEmail,
        type: accountType,
      });

      setRoute('/app');
    } catch (e) {
      setLogin('');
      setPassword('');
      setAuthError(e as ApiError);
    } finally {
      setSigningIn(false);
    }
  };

  return (
    <SignInContext.Provider
      value={{
        login,
        password,
        loginError,
        passwordError,
        authError,
        setLogin,
        setPassword,
        handleSubmit,
        reset,
      }}
    >
      {children}
    </SignInContext.Provider>
  );
};

export const useSignin = () => useContext(SignInContext);
