import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Token } from '@zimbro-app/enums';
import api from '#api';
import { Ledger, RatesByToken } from 'api/types';
import { SafeNumber } from '@zimbro-app/util';

type LedgerContext = {
  ledger: Ledger;
  rates: RatesByToken;
  isLoading: boolean;
  error: Error | undefined;
};

const LedgerContext = createContext<LedgerContext>({
  isLoading: false,
  error: undefined,
  ledger: [],
  rates: {},
});

export const useLedgerContext = () => useContext(LedgerContext);

export const LedgerContextProvider = ({ children }: PropsWithChildren) => {
  const [ledgerIsLoading, setLedgerIsLoading] = useState<boolean>(false);
  const [ratesAreLoading, setRatesAreLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [ledger, setLedger] = useState<Ledger>([]);
  const [rates, setRates] = useState<RatesByToken>({});

  useEffect(() => {
    setRates({
      [Token.Btc]: SafeNumber(6_625_700, 2),
      [Token.Eth]: SafeNumber(332_000, 2),
      [Token.Usdt]: SafeNumber(101, 2),
      [Token.Usdc]: SafeNumber(99, 2),
      [Token.Eurc]: SafeNumber(110, 2),
      [Token.Sol]: SafeNumber(16_128, 2),
    });
    setRatesAreLoading(false);
  }, []);

  useEffect(() => {
    setLedgerIsLoading(true);
    (async () => {
      try {
        const data = await api.post.transactions({});
        setLedger(data);
      } catch (e) {
        setError(e as Error);
      } finally {
        setLedgerIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setIsLoading(ledgerIsLoading || ratesAreLoading);
  }, [ledgerIsLoading, ratesAreLoading]);

  const value = { ledger, rates, isLoading, error };

  return (
    <LedgerContext.Provider value={value}>{children}</LedgerContext.Provider>
  );
};
