import { FC } from 'react';
import { useSignup, SignupProvider } from './Context';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import AccountType from './AccountType';
import AccountEmail from './AccountEmail';
import AccountPassword from './AccountPassword';
import AccountAgreement from './AccountAgreement';

const Signup: FC = () => {
  const { t } = useTranslation();
  const { step, isValidSoFar, prevStep, nextStep, regError, handleSubmit } =
    useSignup();

  return (
    <>
      {regError && (
        <div className='uk-alert-danger' data-uk-alert>
          <a href='#' className='uk-alert-close' data-uk-close></a>
          <p>{`${regError.name && `${regError.name}: `}${regError.message || regError}`}</p>
        </div>
      )}
      <form onSubmit={e => e.preventDefault()} className='zimbro-form'>
        {step === 1 && <AccountType />}
        {step === 2 && <AccountEmail />}
        {step === 3 && <AccountPassword />}
        {step === 4 && <AccountAgreement />}
        <div
          data-uk-grid
          className={cx('uk-grid-small', 'uk-flex-center', 'uk-margin-top')}
        >
          {step > 1 && (
            <div>
              <a
                onClick={prevStep}
                className='uk-button uk-button-secondary uk-border-pill'
              >
                {t('button.next', 'Back')}
              </a>
            </div>
          )}
          <div>
            <a
              href='#'
              onClick={step === 4 ? handleSubmit : nextStep}
              className={cx('uk-button', 'uk-border-pill', {
                'uk-button-primary': step === 4,
                'uk-button-default': step !== 4,
                'zimbro-button-disabled': !isValidSoFar(),
                'uk-text-muted': !isValidSoFar(),
                'uk-disabled': !isValidSoFar(),
              })}
            >
              {step === 4
                ? t('button.next', 'Submit')
                : t('button.next', 'Next')}
            </a>
          </div>
        </div>
      </form>
    </>
  );
};

export default () => {
  const { t } = useTranslation();

  return (
    <SignupProvider>
      <h1 className='uk-text-center uk-h1'>{t('signup.title', 'Sign Up')}</h1>
      {/* <p className='uk-text-center uk-text-muted'>
        {t('signup.subtitle', 'Create your account')}
      </p> */}
      <Signup />
    </SignupProvider>
  );
};
