import { useTranslation } from 'react-i18next';
import {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  FC,
  ChangeEvent,
  FormEvent,
  useEffect,
  useCallback,
} from 'react';

import { isValidEmail } from '#util';
import api from '#api';
import {
  PasswordNoDigit,
  PasswordNoLowercase,
  PasswordNoSpecial,
  PasswordNoUppercase,
  PasswordTooShort,
  hashPassword,
  isValidPassword,
} from '#util';

import { useAppContext } from '#components/App/Context';

interface SignupData {
  accountType: 'personal' | 'business';
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  agreedToTerms: boolean;
  agreedToAmlKyc: boolean;
}

interface SignUpContext {
  step: number;
  data: SignupData;
  error: Partial<SignupData>;
  regError: Error | undefined;
  nextStep: () => void;
  prevStep: () => void;
  isValidSoFar: () => boolean;
  handleChange: (
    input: keyof SignupData,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: FormEvent) => Promise<void>;
}

const defaultData: SignupData = {
  accountType: 'personal',
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  agreedToTerms: false,
  agreedToAmlKyc: false,
};

const SignupContext = createContext<SignUpContext>(null!);
const MIN_PASSWORD_LENGTH = 12;

export const SignupProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [data, setData] = useState<SignupData>(defaultData);
  const [error, setError] = useState<Partial<SignupData>>({});
  const [regError, setRegError] = useState<Error | undefined>();
  const [signingUp, setSigningUp] = useState<boolean>(false);
  const { setAccount, setRoute } = useAppContext();

  const validatePassword = useCallback(() => {
    try {
      if (isValidPassword(data.password, MIN_PASSWORD_LENGTH)) {
        setError(e => ({ ...e, password: '' }));
      }
    } catch (e) {
      let message: string;
      switch (true) {
        case e instanceof PasswordTooShort:
          message = t(
            `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`,
          );
          break;
        case e instanceof PasswordNoLowercase:
          message = t('Passowrd must include a lower case letter');
          break;
        case e instanceof PasswordNoUppercase:
          message = t('Password must include an uppercase letter');
          break;
        case e instanceof PasswordNoDigit:
          message = t('Password must include a digit');
          break;
        case e instanceof PasswordNoSpecial:
          message = t('Password must include a special character');
          break;
        default:
          message = t('Unknown password error');
      }
      setError(e => ({
        ...e,
        password: message,
      }));
      throw e;
    }
  }, [data.password, t]);

  useEffect(() => {
    try {
      if (data.password) {
        validatePassword();
      }
    } catch (e) {}
  }, [data.password, t]);

  const validatePasswordConfirmation = useCallback(() => {
    if (data.password !== data.passwordConfirmation) {
      setError(e => ({
        ...e,
        passwordConfirmation: t(
          'validation.passwordsMismatch',
          'Passwords do not match',
        ),
      }));
      throw new Error('Passwords do not match');
    } else {
      setError(e => ({ ...e, passwordConfirmation: '' }));
    }
  }, [data.password, data.passwordConfirmation, t]);

  useEffect(() => {
    try {
      if (data.password && data.passwordConfirmation) {
        validatePasswordConfirmation();
      }
    } catch (e) {}
  }, [data.password, data.passwordConfirmation]);

  const validateEmail = useCallback(() => {
    if (isValidEmail(data.email)) {
      setError(e => ({ ...e, email: '' }));
    } else {
      setError(e => ({
        ...e,
        email: t(
          'validation.invalidEmail',
          'Please enter a valid email address.',
        ),
      }));
      throw new Error('Invalid email');
    }
  }, [data.email, t]);

  useEffect(() => {
    try {
      if (data.email) {
        validateEmail();
      }
    } catch (e) {}
  }, [data.email, t]);

  const nextStep = () => {
    try {
      if (step === 2) {
        validateEmail();
      } else if (step === 3) {
        validatePassword();
        validatePasswordConfirmation();
      }
      setStep(s => s + 1);
    } catch (e) {}
  };

  const isValidSoFar = () => {
    try {
      if (step < 2) {
        return !!data.accountType;
      } else if (step < 3) {
        return isValidEmail(data.email);
      } else if (step < 4) {
        return (
          isValidPassword(data.password, MIN_PASSWORD_LENGTH) &&
          data.password === data.passwordConfirmation
        );
      } else {
        return data.agreedToTerms && data.agreedToAmlKyc;
      }
    } catch {
      return false;
    }
  };

  const prevStep = () => setStep(s => Math.max(1, s - 1));

  const handleChange =
    (input: keyof SignupData) => (event: ChangeEvent<HTMLInputElement>) =>
      setData(s => ({
        ...s,
        [input]: typeof s[input] === 'boolean' ? !s[input] : event.target.value,
      }));

  const handleSubmit = async (event: FormEvent) => {
    if (signingUp) {
      return;
    }
    event.preventDefault();

    try {
      setSigningUp(true);
      const { accountId, accountName, accountEmail, accountType } =
        await api.post.signUp({
          email: data.email,
          name: data.name,
          password: hashPassword(data.password),
          type: data.accountType,
        });

      setAccount({
        id: accountId,
        name: accountName,
        email: accountEmail,
        type: accountType,
      });
      setRoute('/app');
    } catch (e) {
      setData({
        email: '',
        name: '',
        password: '',
        passwordConfirmation: '',
        agreedToTerms: false,
        agreedToAmlKyc: false,
        accountType: 'personal',
      });

      setRegError(e as Error);
    } finally {
      setSigningUp(false);
    }
  };

  return (
    <SignupContext.Provider
      value={{
        step,
        data,
        error,
        regError,
        nextStep,
        prevStep,
        isValidSoFar,
        handleChange,
        handleSubmit,
      }}
    >
      {children}
    </SignupContext.Provider>
  );
};

export const useSignup = () => useContext(SignupContext);
