import cx from 'classnames';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import { Payment } from 'api/types';
import { ellipsize, formatSum } from '#util';
import { ErrorView, LoadIndicator } from '#components';
import {
  PaymentDetailsContextProvider,
  usePaymentDetailsContext,
} from './Context';

const Copyable: FC<
  PropsWithChildren<{
    title: string;
    value: string;
    className?: string;
    release?: number;
  }>
> = ({ children, value, className, title, release = 2500 }) => {
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    if (!clicked) {
      return;
    }
    const timeout = setTimeout(() => setClicked(false), release);
    return () => clearTimeout(timeout);
  }, [clicked]);

  return (
    <a
      href='#'
      title={title}
      className={cx(
        'uk-flex',
        'uk-flex-middle',
        'uk-flex-center',
        'uk-link-toggle',
        className,
      )}
      onClick={e => (
        e.preventDefault(),
        e.stopPropagation(),
        navigator.clipboard.writeText(value),
        setClicked(true)
      )}
    >
      {children}
      <span
        className={cx('uk-margin-small-left', 'uk-link-muted', 'uk-icon-link')}
        data-uk-icon={clicked ? 'check' : 'copy'}
        onClick={e => e.preventDefault()}
      />
    </a>
  );
};

const PaymentMethod = () => {
  const { t } = useTranslation();
  const { payment, paymentUrl, isLoading, error, rollback, cancel } =
    usePaymentDetailsContext();

  return isLoading ? (
    <LoadIndicator />
  ) : error || !payment || !payment.paymentAmount ? (
    <ErrorView error={error || new Error('Invalid payment')} />
  ) : (
    <div
      className={cx(
        'uk-margin-small-top',
        'uk-flex',
        'uk-flex-column',
        'uk-flex-center',
      )}
    >
      <p className={cx('uk-text-muted', 'uk-text-small', 'uk-text-center')}>
        {t(
          'payment.check-out.instruction',
          'To complete your order please make the payment, ' +
            'or scan the QR-code with your wallet app',
        )}
      </p>
      <Copyable
        title={t('payment.details.address.copy', 'Copy payment address')}
        value={payment.wallet}
        className={cx('uk-margin-small-bottom')}
      >
        <span className={cx('uk-text-large')}>
          {ellipsize.mid(payment.wallet, 24)}
        </span>
      </Copyable>
      <Copyable
        title={t('payment.details.address.copy', 'Copy payment amount')}
        value={payment.paymentAmount.format.toString()}
        className={cx('uk-margin-bottom')}
      >
        <span className={cx('uk-text-large')}>
          {formatSum(payment.paymentAmount.format, {
            decimals: Math.min(payment.paymentAmount.decimals, 8),
          })}
          &nbsp;{payment.paymentToken}
        </span>
      </Copyable>
      <div
        className={cx('uk-text-center', 'uk-margin-top', 'uk-margin-bottom')}
      >
        <QRCodeSVG size={256} value={paymentUrl} fgColor='#193952' />
      </div>
      <div
        className={cx(
          'uk-flex',
          'uk-flex-around',
          'uk-margin-medium-top',
          'uk-child-width-1-2',
        )}
      >
        <a
          href='#'
          onClick={e => (e.preventDefault(), rollback())}
          className={cx(
            'uk-button',
            'uk-button-default',
            'uk-border-pill',
            'uk-margin-small-right',
          )}
        >
          {t('payment/method/button/back', 'Change Method')}
        </a>
        <a
          href='#'
          onClick={e => (e.preventDefault(), cancel())}
          className={cx(
            'uk-button',
            'uk-button-danger',
            'uk-border-pill',
            'uk-margin-small-left',
          )}
        >
          {t('Cancel Payment')}
        </a>
      </div>
    </div>
  );
};

export default (props: { payment: Payment }) => (
  <PaymentDetailsContextProvider {...props}>
    <PaymentMethod />
  </PaymentDetailsContextProvider>
);
