import { SHA256, enc } from 'crypto-js';

export class InvalidPassword extends Error {}
export class PasswordTooShort extends InvalidPassword {}
export class PasswordNoUppercase extends InvalidPassword {}
export class PasswordNoLowercase extends InvalidPassword {}
export class PasswordNoDigit extends InvalidPassword {}
export class PasswordNoSpecial extends InvalidPassword {}

export const isValidPassword = (p: string, minLength: number): boolean => {
  if (p.length < minLength) {
    throw new PasswordTooShort();
  } else if (!/[a-z]/.test(p)) {
    throw new PasswordNoLowercase();
  } else if (!/[A-Z]/.test(p)) {
    throw new PasswordNoUppercase();
  } else if (!/\d/.test(p)) {
    throw new PasswordNoDigit();
  } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(p)) {
    throw new PasswordNoSpecial();
  } else {
    return true;
  }
};

export const hashPassword = (p: string): string => SHA256(p).toString(enc.Hex);
