import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSignup } from './Context';
import { InputError } from '#components';

const AccountPassword = () => {
  const { t } = useTranslation();
  const { data, error, handleChange } = useSignup();
  return (
    <div>
      <h2 className='uk-h2 uk-text-center'>{t('Account password')}</h2>
      <p className='uk-text-center uk-text-muted'>
        {t('Please enter and confirm your password')}
      </p>
      <div
        data-uk-grid
        className={cx('uk-grid-small', 'uk-flex-center', 'uk-padding-small')}
      >
        <div className={cx('uk-width-1-1', 'uk-margin-small-bottom')}>
          <div
            className={cx(
              'uk-width-1-3@m',
              'uk-width-1-1@s',
              'uk-align-center',
              'zimbro-form-input-box',
            )}
          >
            <label htmlFor='sign-up-account-password' className='uk-form-label'>
              {t('Account Password')}:
            </label>
            <input
              id='sign-up-account-password'
              className={cx('uk-input', 'uk-border-pill', {
                'uk-form-danger': !!error.password,
              })}
              type='password'
              value={data.password}
              onChange={handleChange('password')}
              placeholder='Enter password'
            />
            <InputError error={error.password} />
          </div>
        </div>
        <div className={cx('uk-width-1-1', 'uk-margin-small-bottom')}>
          <div
            className={cx(
              'uk-width-1-3@m',
              'uk-width-1-1@s',
              'uk-align-center',
              'zimbro-form-input-box',
            )}
          >
            <label
              htmlFor='sign-up-password-confirmation'
              className='uk-form-label'
            >
              {t('Password Confirmation')}:
            </label>
            <input
              id='sign-up-password-confirmation'
              className={cx('uk-input', 'uk-border-pill', {
                'uk-form-danger': !!error.passwordConfirmation,
              })}
              type='password'
              value={data.passwordConfirmation}
              onChange={handleChange('passwordConfirmation')}
              placeholder='Confirm password'
            />
            <InputError error={error.passwordConfirmation} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPassword;
