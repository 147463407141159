import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Purpose from '../Purpose';
import { useDashboardContext } from '#components/Dashboard/Context';
import { LedgerContextProvider, useLedgerContext } from './Context';
import { ErrorView, LoadIndicator } from '#components';
import {
  when,
  formatSum,
  coinIcons as icons,
  formatUsd,
  formatDatetime,
} from '#util';

const Ledger = ({ onCreateClick }: { onCreateClick: () => void }) => {
  const { t } = useTranslation();
  const { account } = useDashboardContext();
  const { ledger, rates, isLoading, error } = useLedgerContext();

  return (
    <>
      <div
        className={cx(
          'uk-flex',
          'uk-flex-middle',
          'uk-flex',
          'uk-flex-between',
          'uk-margin-bottom',
        )}
      >
        <h2 className={cx('uk-h2', 'uk-margin-remove')}>
          {t('dashboard.tx-list.title', 'Payments')}
        </h2>
        <div className={cx('uk-flex-shrink')}>
          <a
            href='#'
            className={cx('uk-link-muted', 'uk-margin-left')}
            title={t('dashboard.vendors.create', 'Create Vendor')}
            data-uk-icon='icon: plus-circle; ratio: 1.25'
            onClick={e => (e.preventDefault(), onCreateClick())}
          />
        </div>
      </div>
      {isLoading ? (
        <LoadIndicator ratio={1} />
      ) : error ? (
        <ErrorView
          title={t('ledger.error', 'Failed to load transactions')}
          error={error}
        />
      ) : !ledger.length ? (
        <div className={cx('uk-text-center')}>
          <h4 className={cx('uk-h4')}>
            {t('dashboard.tx.no-tx', 'No transactions yet')}
          </h4>
          <p className={cx('uk-text-muted')}>
            {t(
              'dashboard.tx.no-tx.hint',
              'Create your first payment and start receiving transactions',
            )}
          </p>
          <a
            onClick={onCreateClick}
            className='uk-button uk-button-secondary uk-border-pill'
          >
            {t('dashboard.tx.create-payment', 'Create Payment')}
          </a>
        </div>
      ) : (
        <table
          className={cx('uk-table', 'uk-table-justify', 'uk-table-middle ')}
        >
          <tbody>
            {ledger.map(tx => (
              <tr key={`${tx.txHash}-${tx.source}-${tx.target}`}>
                <td className={cx('uk-table-shrink')} style={{ width: '36px' }}>
                  <img
                    src={icons[tx.token.toLowerCase()]}
                    width='36'
                    height='auto'
                    alt={tx.token}
                  />
                </td>
                <td className={cx('uk-table-shrink')}>
                  <span className={cx('uk-flex', 'uk-flex-column')}>
                    <span className={cx('uk-text-nowrap')}>
                      {when(tx.timestamp)}
                    </span>
                    <span
                      className={cx(
                        'uk-text-nowrap',
                        'uk-text-muted',
                        'uk-text-small',
                      )}
                    >
                      {formatDatetime(tx.timestamp)}
                    </span>
                  </span>
                </td>
                <td className={cx('uk-table-shrink')}>
                  <span className={cx('uk-flex', 'uk-flex-column')}>
                    <span className={cx('uk-text-right')}>
                      <span
                        className={cx(
                          tx.sourceAccount === account.id
                            ? 'uk-text-danger'
                            : 'uk-text-success',
                        )}
                      >
                        {(tx.sourceAccount === account.id ? '-' : '+') +
                          formatSum(tx.amount.number, {
                            decimals: tx.amount.decimals,
                          })}
                      </span>
                      <span className={cx('z-space-before')}>{tx.token}</span>
                    </span>
                    <span
                      className={cx(
                        'uk-text-right',
                        'uk-text-muted',
                        'uk-text-small',
                      )}
                    >
                      <span>
                        {(tx.sourceAccount === account.id ? '-' : '+') +
                          formatUsd(rates[tx.token]!.mul(tx.amount).number)}
                      </span>
                    </span>
                  </span>
                </td>
                <td>
                  <Purpose {...tx} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ({ onCreateClick }: { onCreateClick: () => void }) => (
  <LedgerContextProvider>
    <Ledger onCreateClick={onCreateClick} />
  </LedgerContextProvider>
);
