import cx from 'classnames';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardContextProvider } from './Context';
import { Balance, Vendors, Payments, Page } from '#components';

import './index.less';

enum Tab {
  Balance,
  Payments,
  Vendors,
}

const DashboarTab: FC<{
  isActive: boolean;
  onClick: () => void;
  title: string;
}> = ({ isActive, onClick, title }) => (
  <li className={cx({ 'uk-active': isActive })}>
    <a href='#' onClick={e => (e.preventDefault(), onClick())}>
      {title}
    </a>
  </li>
);

const Dashboard = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<Tab>(Tab.Balance);
  return (
    <Page title={t('dashboard.title', 'Dashboard')} sectionClassName=''>
      <ul data-uk-tab>
        <DashboarTab
          isActive={tab === Tab.Balance}
          onClick={() => setTab(Tab.Balance)}
          title={t('dashboard.tabs.balance', 'Balance')}
        />
        <DashboarTab
          isActive={tab === Tab.Payments}
          onClick={() => setTab(Tab.Payments)}
          title={t('dashboard.tabs.payments', 'Payments')}
        />
        <DashboarTab
          isActive={tab === Tab.Vendors}
          onClick={() => setTab(Tab.Vendors)}
          title={t('dashboard.tabs.vendors', 'Vendors')}
        />
      </ul>
      {tab === Tab.Balance && <Balance />}
      {tab === Tab.Vendors && <Vendors />}
      {tab === Tab.Payments && <Payments />}
    </Page>
  );
};

export default () => (
  <DashboardContextProvider>
    <Dashboard />
  </DashboardContextProvider>
);
