import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ellipsize, formatDate } from '#util';
import { useVendorListContext, VendorListContextProvider } from './Context';
import { ErrorView, LoadIndicator } from '#components';

export type VendorListProps = {
  className?: string;
  onItemClick: (id: string) => void;
  onCreateClick: () => void;
};

const VendorList = ({
  className,
  onItemClick,
  onCreateClick,
}: VendorListProps) => {
  const { t } = useTranslation();
  const { vendors, isLoading, error } = useVendorListContext();
  return (
    <>
      <div
        className={cx(
          'uk-flex',
          'uk-flex-middle',
          'uk-flex',
          'uk-flex-between',
          'uk-margin-bottom',
        )}
      >
        <h2 className={cx('uk-h2', 'uk-margin-remove')}>
          {t('dashboard.vendors.title', 'Vendors')}
        </h2>
        <div className={cx('uk-flex-shrink')}>
          <a
            href='#'
            className={cx('uk-link-muted', 'uk-margin-left')}
            title={t('dashboard.vendors.create', 'Create Vendor')}
            data-uk-icon='icon: plus-circle; ratio: 1.25'
            onClick={e => (e.preventDefault(), onCreateClick())}
          />
        </div>
      </div>
      {isLoading ? (
        <LoadIndicator ratio={4} />
      ) : error ? (
        <ErrorView
          title={t('dashboard.vendors.error', 'Failed to load vendors')}
          error={error}
        />
      ) : !vendors.length ? (
        <div className={cx('uk-text-center')}>
          <h4 className={cx('uk-h4')}>
            {t('dashboard.vendors.empty', 'No vendors yet')}
          </h4>
          <p className={cx('uk-text-muted')}>
            {t(
              'dashboard.vendors.empty.hint',
              'Create your first vendor and start receiving payments',
            )}
          </p>
          <a
            href='#'
            title={t('dashboard.vendors.create', 'Create Vendor')}
            onClick={e => (e.preventDefault(), onCreateClick())}
            className='uk-button uk-button-secondary uk-border-pill'
          >
            {t('dashboard.vendorsf.create-vendor', 'Create Vendor')}
          </a>
        </div>
      ) : (
        <table
          className={cx(
            'uk-table',
            'uk-table-middle',
            'uk-table-hover',
            className,
          )}
        >
          <thead>
            <tr>
              <th>{t('dashboard.vendors.head.name', 'Name')}</th>
              <th>{t('dashboard.vendors.head.website', 'Website')}</th>
              <th>{t('dashboard.vendors.head.modified', 'Last modified')}</th>
              <th className={cx('uk-table-shrink')} style={{ width: '32px' }}>
                &nbsp;
              </th>
              <th className={cx('uk-table-shrink')} style={{ width: '32px' }}>
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            {vendors.map(v => (
              <tr key={v.vendorId} onClick={() => onItemClick(v.vendorId)}>
                <td className={cx()}>
                  <span className={cx('uk-flex', 'uk-flex-column')}>
                    <span className={cx('uk-text-nowrap')}>{v.vendorName}</span>
                    {v.fullName && (
                      <span
                        className={cx(
                          'uk-text-nowrap',
                          'uk-text-muted',
                          'uk-text-small',
                        )}
                      >
                        {v.fullName}
                      </span>
                    )}
                  </span>
                </td>
                <td>
                  <a
                    href={v.origin}
                    target='_blank'
                    className={cx('uk-text-nowrap')}
                  >
                    {ellipsize(v.origin, 64)}
                  </a>
                </td>
                <td>
                  <span className={cx('uk-flex', 'uk-flex-column')}>
                    <span className={cx('uk-text-nowrap')}>
                      {formatDate(v.updatedAt)}
                    </span>
                  </span>
                </td>
                <td>
                  <a
                    href='#'
                    className={cx('uk-link-muted')}
                    data-uk-icon='icon: pencil'
                    title={t('dashboard.vendors.edit', 'Edit Vendor')}
                  />
                </td>
                <td>
                  <a
                    href='#'
                    className={cx('uk-link-muted')}
                    data-uk-icon='icon: trash'
                    title={t('dashboard.vendors.delete', 'Delete Vendor')}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default (props: VendorListProps) => (
  <VendorListContextProvider>
    <VendorList {...props} />
  </VendorListContextProvider>
);
