import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSignup } from './Context';
import { InputError } from '#components';

const AccountEmail = () => {
  const { t } = useTranslation();
  const { data, error, handleChange } = useSignup();
  return (
    <div>
      <h2 className='uk-h2 uk-text-center'>{t('Account name')}</h2>
      <p className='uk-text-center uk-text-muted'>
        {t('Please enter account email and name')}
      </p>
      <div
        data-uk-grid
        className={cx('uk-grid-small', 'uk-flex-center', 'uk-padding-small')}
      >
        <div className={cx('uk-width-1-1', 'uk-margin-small-bottom')}>
          <div
            className={cx(
              'uk-width-1-3@m',
              'uk-width-1-1@s',
              'uk-align-center',
              'zimbro-form-input-box',
            )}
          >
            <label
              htmlFor='sign-up-account-email'
              className={t('uk-form-label')}
            >
              {t('Email')}:
            </label>
            <input
              id='sign-up-account-email'
              className={cx('uk-input', 'uk-border-pill', {
                'uk-form-danger': !!error.email,
              })}
              type='email'
              value={data.email}
              onChange={handleChange('email')}
              placeholder='Enter your email'
            />
            <InputError error={error.email} />
          </div>
        </div>
        <div className={cx('uk-width-1-1', 'uk-margin-small-bottom')}>
          <div
            className={cx(
              'uk-width-1-3@m',
              'uk-width-1-1@s',
              'uk-align-center',
              'zimbro-form-input-box',
            )}
          >
            <label
              htmlFor='sign-up-account-name'
              className={t('uk-form-label')}
            >
              {t('Account Name (optional)')}:
            </label>
            <input
              id='sign-up-account-name'
              className={cx('uk-input', 'uk-border-pill')}
              type='text'
              value={data.name}
              onChange={handleChange('name')}
              placeholder='Enter your name'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountEmail;
