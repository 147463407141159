import * as React from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { AppContextProvider } from './Context';
import { Navbar } from '#components';

// For some reason "export default as" doesn't work with router
import Home from '#components/Home';
import Signin from '#components/Signin';
import Signup from '#components/Signup';
import Dashboard from '#components/Dashboard';
import Payment from '#components/Payment';
import PaymentRequest from '#components/Payment/Request';

import './index.less';

const Index = () => (
  <AppContextProvider>
    <Navbar />
    <Outlet />
  </AppContextProvider>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/signin',
        element: <Signin />,
      },
      {
        path: '/signup',
        element: <Signup />,
      },
      {
        path: '/app',
        element: <Dashboard />,
      },
    ],
  },
  {
    path: '/payment',
    element: <PaymentRequest />,
  },
  {
    path: '/payment/:paymentId',
    element: <Payment />,
  },
]);

export default () => (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
