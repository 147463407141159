import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '#components/App/Context';

import './index.less';
import Logo from '#components/Logo';

export default () => {
  const { t } = useTranslation();
  const { account, signOut } = useAppContext();
  return (
    <nav
      className={cx(
        'uk-navbar-container',
        'uk-navbar-transparent',
        'uk-container',
      )}
      data-uk-navbar
    >
      <ul className='uk-navbar-nav uk-navbar-left'>
        <li>
          <Logo width={56} />
        </li>
        <li className='uk-active'>
          <a href='#'>{t('About')}</a>
        </li>
        <li>
          <a href='#'>{t("What's new")}</a>
        </li>
        <li>
          <a href='#'>{t('Help')}</a>
        </li>
      </ul>
      <div
        className={cx({
          'uk-navbar-right': 1,
          'nav-bar-dense': !account || !account.id,
        })}
      >
        {account && account.id ? (
          <>
            <NavLink to='/app'>{account.name || account.email}</NavLink>
            <a
              href='#'
              onClick={signOut}
              className={cx(
                'uk-button',
                'uk-button-default',
                'uk-border-pill',
                'primary-inverse',
              )}
            >
              {t('Sign-out')}
            </a>
          </>
        ) : (
          <>
            <NavLink
              to='/signin'
              className='uk-button uk-button-default uk-border-pill primary-inverse'
            >
              {t('Sign-in')}
            </NavLink>
            <NavLink
              to='/signup'
              className='uk-button uk-button-primary uk-border-pill'
            >
              {t('Sign-up')}
            </NavLink>
          </>
        )}
      </div>
    </nav>
  );
};
