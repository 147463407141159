import api from '#api';
import { usePaymentContext } from '../Context';
import { Chain, Token } from '@zimbro-app/enums';
import { BaseError } from '@zimbro-app/util';
import { Payment, PostTokensReply } from 'api/types';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export type TokenOption = { value: Token; label: string };
export type ChainOption = { value: Chain; label: string };

type PaymentMethodContext = {
  isLoading: boolean;
  payment: Payment;
  error?: Error;
  token?: TokenOption;
  chain?: ChainOption;
  setToken: (token: TokenOption) => void;
  setChain: (chain: ChainOption) => void;
  tokens: TokenOption[];
  chains: ChainOption[];
  submit: () => void;
  tokenError?: Error;
  chainError?: Error;
};

const PaymentMethodContext = createContext<PaymentMethodContext>({
  isLoading: true,
  setToken: () => {},
  setChain: () => {},
  tokens: [],
  chains: [],
  payment: {} as Payment,
  submit: () => {},
});

export const usePaymentMethodContext = () => useContext(PaymentMethodContext);

class PaymentMethodError extends BaseError {}

export const PaymentMethodContextProvider: FC<
  PropsWithChildren<{ payment: Payment }>
> = ({ children, payment }) => {
  const { t } = useTranslation();
  const { updatePayment } = usePaymentContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();
  const [token, setToken] = useState<TokenOption | undefined>(undefined);
  const [chain, setChain] = useState<ChainOption | undefined>(undefined);
  const [tokens, setTokens] = useState<TokenOption[]>([]);
  const [chains, setChains] = useState<ChainOption[]>([]);
  const [tokenError, setTokenError] = useState<Error | undefined>();
  const [chainError, setChainError] = useState<Error | undefined>();
  const [tokenData, setTokenData] = useState<PostTokensReply | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const reply = await api.post.tokens({
          pageSize: 100,
          filter: { isTest: false, isSupported: true },
        });
        setTokenData(reply);
        setTokens(
          Object.values(reply.tokens).map(({ tokenSymbol, tokenName }) => ({
            value: tokenSymbol,
            label: tokenName || tokenSymbol,
          })),
        );
        setChains(
          Object.values(reply.chains).map(({ chainName, chainFullName }) => ({
            value: chainName,
            label: chainFullName,
          })),
        );
      } catch (cause) {
        const error = new PaymentMethodError('Failed to load token data', {
          cause,
        });

        setError(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const submit = async () => {
    if (!token || !chain || !payment) {
      if (!token) {
        setTokenError(
          new Error(
            t('payment/method/token/required', 'Please specify payment token'),
          ),
        );
      } else if (!chain) {
        setChainError(
          new Error(
            t(
              'payment/method/chain/required',
              'Please specify payment network',
            ),
          ),
        );
      } else {
        setError(
          new Error(t('payment/method/payment/invalid', 'Invalid Payment')),
        );
      }
      return;
    }
    setIsLoading(true);
    try {
      const reply = await api.patch.paymentMethod({
        paymentId: payment.paymentId,
        orderId: payment.orderId,
        paymentToken: token.value,
        paymentChain: chain.value,
      });
      if (!reply) {
        throw 'Invalid Reply';
      }
      updatePayment(reply);
    } catch (cause) {
      setError(
        new PaymentMethodError('Payment method error', {
          cause,
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    error,
    payment,
    isLoading,
    setToken: (token: TokenOption) => {
      if (token) {
        setTokenError(undefined);
        setToken(token);
        if (
          chain &&
          tokenData &&
          !(token.value in tokenData.chains[chain.value]!.tokens)
        ) {
          setChain(undefined);
        }
        if (tokenData && tokenData.tokens && tokenData.tokens[token.value]) {
          const chains = Object.values(tokenData.tokens[token.value]!.chains);
          setChains(
            chains.map(({ chainName, chainFullName }) => ({
              value: chainName,
              label: chainFullName,
            })),
          );
          if (chains.length === 1) {
            setChain({
              value: chains[0].chainName,
              label: chains[0].chainFullName,
            });
          }
        }
      } else {
        setTokenError(
          new Error(
            t('payment/method/token/required', 'Please specify payment token'),
          ),
        );
      }
    },
    setChain: (chain: ChainOption) => {
      if (chain) {
        setChainError(undefined);
        setChain(chain);
      } else {
        setChainError(
          new Error(
            t(
              'payment/method/chain/required',
              'Please specify payment network',
            ),
          ),
        );
      }
    },
    token,
    chain,
    tokens,
    chains,
    submit,
    tokenError,
    chainError,
  };

  return (
    <PaymentMethodContext.Provider value={value}>
      {children}
    </PaymentMethodContext.Provider>
  );
};
