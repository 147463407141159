const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const MONTH = 30 * DAY;
const YEAR = 365 * DAY;

export const when = (
  x: Date,
  cur = new Date(),
  {
    style = 'short',
    secPrecision = 15,
  }: { style?: Intl.RelativeTimeFormatStyle; secPrecision?: number } = {},
) => {
  console.assert(
    secPrecision > 1 && secPrecision < 60,
    'Seconds precision should be between 1 and 60',
  );
  const timestamp = x.getTime();
  const ms: number = cur.getTime() - timestamp;
  const rtf = new Intl.RelativeTimeFormat('en-US', { style, numeric: 'auto' });
  const diff = Math.abs(ms);
  if (diff < MINUTE) {
    const mins = -Math.floor(ms / (SECOND * secPrecision)) * secPrecision;
    return rtf.format(mins, 'second');
  } else if (diff < HOUR) {
    const mins = -Math.floor(ms / MINUTE);
    return rtf.format(mins, 'minute');
  } else if (diff < DAY / 2) {
    const hours = -Math.floor(ms / HOUR);
    return rtf.format(hours, 'hour');
  } else if (diff < DAY * 2) {
    const todayStart = new Date().setHours(0, 0, 0);
    let days: number;
    if (ms > 0) {
      if (timestamp > todayStart) {
        days = 0;
      } else {
        const yesterdayStart = new Date(todayStart - DAY).setHours(0, 0, 0);
        days = timestamp > yesterdayStart ? -1 : -2;
      }
    } else {
      const tomorrowStart = new Date(todayStart + DAY).setHours(0, 0, 0);
      if (timestamp < tomorrowStart) {
        days = 0;
      } else {
        const nextDayStart = new Date(tomorrowStart + DAY).setHours(0, 0, 0);
        days = timestamp < nextDayStart ? 1 : 2;
      }
    }
    return rtf.format(days, 'day');
  } else if (diff < MONTH) {
    const days = -Math.floor(ms / DAY);
    return rtf.format(days, 'day');
  } else if (diff < YEAR && x.getFullYear() === cur.getFullYear()) {
    const months = -Math.floor(ms / MONTH);
    return rtf.format(months, 'month');
  } else {
    return formatDate(x);
  }
};

export const formatCountdown = (diff: number) => {
  diff = Math.abs(diff);
  const units = [];
  if (diff > HOUR) {
    units.push(String(Math.floor((diff % DAY) / HOUR)).padStart(2, '0'));
  }
  units.push(
    String(Math.floor(((diff % DAY) % HOUR) / MINUTE)).padStart(2, '0'),
  );
  units.push(
    String(Math.floor((((diff % DAY) % HOUR) % MINUTE) / SECOND)).padStart(
      2,
      '0',
    ),
  );

  return units.join(':');
};

export const formatTimeRange = (
  x: Date,
  cur = new Date(),
  { style = 'short' }: { style?: Intl.RelativeTimeFormatStyle } = {},
) => {
  const timestamp = x.getTime();
  const ms: number = cur.getTime() - timestamp;
  const rtf = new Intl.RelativeTimeFormat('en-US', {
    style,
    numeric: 'always',
  });
  const diff = Math.abs(ms);
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  if (diff > DAY) {
    days = Math.floor(diff / DAY);
  }
  if (diff > HOUR) {
    hours = Math.floor((diff % DAY) / HOUR);
  }
  if (diff > MINUTE) {
    minutes = Math.floor(((diff % DAY) % HOUR) / MINUTE);
  }
  if (diff > SECOND) {
    seconds = Math.floor((((diff % DAY) % HOUR) % MINUTE) / SECOND);
  }

  return `${days ? `${rtf.format(days, 'day')} ` : ''}${
    hours
      ? `${rtf
          .formatToParts(hours, 'hour')
          .slice(days ? 1 : 0)
          .map(p => p.value)
          .join('')} `
      : ''
  }${
    minutes
      ? `${rtf
          .formatToParts(minutes, 'minute')
          .slice(hours ? 1 : 0)
          .map(p => p.value)
          .join('')} `
      : ''
  }${
    seconds
      ? `${rtf
          .formatToParts(seconds, 'second')
          .slice(minutes ? 1 : 0)
          .map(p => p.value)
          .join('')} `
      : ''
  }`;
};

export const formatDate = (x: Date) =>
  new Intl.DateTimeFormat('en-US', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    dateStyle: 'medium',
  }).format(x);

export const formatDatetime = (x: Date) =>
  new Intl.DateTimeFormat('en-US', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(x);
