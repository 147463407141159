import { Blockchain, BlockchainsByChain } from '@zimbro-app/enums';
import { Payment } from 'api/types';

export const getQrCodeUrl = (payment: Payment): string => {
  if (!payment.wallet) {
    throw new Error('Recipient Address is not defined');
  }
  if (!payment.paymentAmount) {
    throw new Error('Payment amount is not defined');
  }
  if (!payment.paymentChain || !payment.paymentChainFullName) {
    throw new Error('Payment chain is not defined');
  }

  const blockchain = BlockchainsByChain[payment.paymentChain!];

  if (!blockchain) {
    throw new Error(`Invalid chain ${payment.paymentChain}`);
  }

  const {
    wallet,
    paymentAmount: amount,
    paymentTokenContract: contract,
    paymentChain: chain,
    paymentChainFullName: label,
  } = payment;

  if (blockchain === Blockchain.Bitcoin) {
    // bitcoin:<address>?amount=<amount_in_btc>&label=<optional_label>&message=<optional_message>

    return `bitcoin:${wallet}?amount=${amount.format}`;
  } else if (blockchain === Blockchain.Ethereum) {
    // ethereum:<address>?value=<amount_in_wei>
    // ethereum:<contract_address>/transfer?address=<recipient_address>&uint256=<amount_in_wei>

    return (
      'ethereum:' +
      (contract
        ? `${contract}/transfer?address=${wallet}&uint256=${amount.string}&network=${chain}`
        : `${wallet}?value=${amount.string}&network=${chain}`)
    );
  } else if (blockchain === Blockchain.Solana) {
    // solana:<address>?amount=<amount_in_sol>&label=<optional_label>&message=<optional_message>
    // solana:<contract_address>/transfer?recipient=<recipient_address>&amount=<amount_in_decimals>

    return (
      'solana:' +
      (contract
        ? `${wallet}?spl-token=${contract}&amount=${amount.format}` //&label=Payment (${label})`
        : `${wallet}?amount=${amount.format}`) //&label=Payment (${label})`)
    );
  } else if (blockchain === Blockchain.Tron) {
    // tron:<address>?amount=<amount_in_trx>&label=<optional_label>&message=<optional_message>
    // tron:<contract_address>/transfer?address=<recipient_address>&amount=<amount_in_decimals>

    return (
      'tron:' +
      (contract
        ? `${contract}/transfer?address=${wallet}&amount=${amount.format}&label=Payment (${label})`
        : `${wallet}?amount=${amount.format}&label=Payment (${label})`)
    );
  } else {
    throw new Error(`Unsupported blockchain: ${blockchain}`);
  }
};
