import cx from 'classnames';
import { Token } from '@zimbro-app/enums';
import { formatSum, formatUsd, coinIcons as icons } from '#util';
import { SafeNumber } from '@zimbro-app/util';

export default ({
  token,
  balance,
  usdBalance,
}: {
  token: Token;
  balance: SafeNumber;
  usdBalance: SafeNumber;
}) => (
  <a
    href='#'
    onClick={e => e.preventDefault()}
    className={cx(
      'uk-flex',
      'uk-flex-between',
      'uk-flex-middle',
      'uk-text-decoration-none',
    )}
  >
    <span>
      <img
        src={icons[token.toLowerCase()]}
        width='32'
        height='auto'
        alt={token}
      />
      <span className={cx('uk-margin-small-left')}>{token}</span>
    </span>
    <span
      className={cx(
        'uk-flex',
        'uk-flex-column',
        'uk-flex-right',
        'uk-text-right',
      )}
    >
      <span>
        {formatSum(balance.format, { decimals: Math.min(8, balance.decimals) })}
      </span>
      <span className={cx('uk-text-muted', 'uk-text-small')}>
        {formatUsd(usdBalance.format)}
      </span>
    </span>
  </a>
);
