import api from '#api';
import { BaseError } from '@zimbro-app/util';
import { Payment } from 'api/types';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

type PaymentContext = {
  updatePayment: (paiment: Payment) => void;
  isLoading: boolean;
  error?: Error;
  payment?: Payment;
};

const PaymentContext = createContext<PaymentContext>({
  isLoading: true,
  updatePayment: () => {},
});

export const usePaymentContext = () => useContext(PaymentContext);

class PaymentError extends BaseError {}

export const PaymentContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const [payment, setPayment] = useState<Payment>();
  const { paymentId } = useParams<{ paymentId: string }>();

  useEffect(() => {
    if (!paymentId) {
      setError(new PaymentError('Undefined Payment'));
      return;
    }
    if (payment) {
      return;
    }
    (async () => {
      try {
        const reply = await api.get.paymentId({ paymentId });
        setPayment(reply);
      } catch (cause) {
        setError(
          new PaymentError('Failed to fetch payment details', {
            cause,
            paymentId,
          }),
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, [paymentId, payment && payment.paymentId]);

  const updatePayment = (payment: Payment) => setPayment(payment);

  const value = {
    error,
    payment,
    isLoading,
    updatePayment,
  };

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
};
