import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { InputError } from '#components';
import {
  useVendorFormContext,
  VendorFormContextProvider,
  VendorFormProps,
} from './Context';

const VendorForm = ({ onClose }: VendorFormProps) => {
  const { t } = useTranslation();
  const {
    isLoading,
    error,
    vendorName,
    fullName,
    origin,
    description,
    vendorNameError,
    fullNameError,
    originError,
    descriptionError,
    setVendorName,
    setFullName,
    setOrigin,
    setDescription,
    submit,
    reset,
  } = useVendorFormContext();

  const isNotValid =
    vendorNameError ||
    fullNameError ||
    originError ||
    descriptionError ||
    !vendorName ||
    !origin;

  return (
    <>
      <div
        className={cx(
          'uk-flex',
          'uk-flex-middle',
          'uk-flex',
          'uk-flex-between',
          'uk-margin-bottom',
        )}
      >
        <h2 className={cx('uk-h2', 'uk-margin-remove')}>
          {t('dashboard.vendor.form.title', 'Add New Vendor')}
        </h2>
        <div className={cx('uk-flex-shrink')}>
          <a
            href='#'
            className={cx('uk-link-muted', 'uk-margin-left')}
            title={t('dashboard.vendor.form.close', 'Back to list')}
            data-uk-icon='icon: close; ratio: 1.25'
            onClick={e => (e.preventDefault(), onClose())}
          />
        </div>
      </div>
      <form
        className={cx(
          'zimbro-form',
          'uk-flex',
          'uk-flex-column',
          'uk-width-1-2@m',
          'uk-width-1-1@s',
        )}
        onSubmit={e => e.preventDefault()}
      >
        <div className={cx('uk-margin-bottom', 'zimbro-form-input-box')}>
          <label
            htmlFor='vendorName'
            className={cx('uk-form-label', {
              'uk-form-danger': !!vendorNameError,
            })}
          >
            {t('dashboard.vendor.form.input.name', 'Vendor Name')}:
          </label>
          <input
            className={cx('uk-input', 'uk-border-pill')}
            type='text'
            id='vendorName'
            value={vendorName}
            onChange={e => setVendorName(e.target.value)}
            placeholder={t(
              'dashboard.vendor.form.placeholder.name',
              'Enter Vendor Name',
            )}
          />
          <InputError error={vendorNameError} />
        </div>
        <div className={cx('uk-margin-bottom', 'zimbro-form-input-box')}>
          <label
            htmlFor='fullName'
            className={cx('uk-form-label', {
              'uk-form-danger': !!fullNameError,
            })}
          >
            {t(
              'dashboard.vendor.form.input.full-name',
              'Full Vendor Name (optional)',
            )}
            :
          </label>
          <input
            className={cx('uk-input', 'uk-border-pill')}
            type='text'
            id='fullName'
            value={fullName}
            onChange={e => setFullName(e.target.value)}
            placeholder={t(
              'dashboard.vendor.form.placeholder.full-name',
              'Enter Full Vendor Name',
            )}
          />
          <InputError error={fullNameError} />
        </div>
        <div className={cx('uk-margin-bottom', 'zimbro-form-input-box')}>
          <label
            htmlFor='origin'
            className={cx('uk-form-label', {
              'uk-form-danger': !!originError,
            })}
          >
            {t('dashboard.vendor.form.input.website', 'Website Address')}:
          </label>
          <input
            className={cx('uk-input', 'uk-border-pill')}
            type='text'
            id='origin'
            value={origin}
            onChange={e => setOrigin(e.target.value)}
            placeholder={t(
              'dashboard.vendor.form.placeholder.website',
              'Enter Website Address',
            )}
          />
          <InputError error={originError} />
        </div>
        <div className={cx('uk-margin-small-bottom', 'zimbro-form-input-box')}>
          <label
            htmlFor='description'
            className={cx('uk-form-label', {
              'uk-form-danger': !!descriptionError,
            })}
          >
            {t(
              'dashboard.vendor.form.input.description',
              'Vendor Description (optional)',
            )}
            :
          </label>
          <textarea
            className={cx('uk-textarea', 'uk-border-rounded')}
            id='description'
            onChange={e => setDescription(e.target.value)}
            placeholder={t(
              'dashboard.vendor.form.placeholder.description',
              'Enter Description',
            )}
            rows={4}
            value={description}
          />
          <InputError error={descriptionError} />
        </div>
        <div
          className={cx('uk-flex', 'uk-flex-center', 'uk-margin-medium-top')}
        >
          {isLoading ? (
            <div data-uk-spinner='ratio: 0.75' />
          ) : (
            <a
              onClick={submit}
              className={cx(
                'uk-button',
                'uk-border-pill',
                'uk-button-primary',
                {
                  'zimbro-button-disabled': isNotValid,
                  'uk-text-muted': isNotValid,
                  'uk-disabled': isNotValid,
                },
              )}
            >
              {t('dashboard.vendor.form.submit', 'Submit')}
            </a>
          )}
        </div>
        {error && (
          <div className={cx('uk-alert-danger')} data-uk-alert>
            <a
              href='#'
              className='uk-alert-close'
              onClick={reset}
              data-uk-close
            />
            <h4>
              {t('dashboard.vendor.form.error-title', 'Vendor creation error')}
            </h4>
            <p>{error}</p>
          </div>
        )}
      </form>
    </>
  );
};

export default (props: VendorFormProps) => (
  <VendorFormContextProvider {...props}>
    <VendorForm {...props} />
  </VendorFormContextProvider>
);
