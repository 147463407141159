import api from '#api';
import { Token } from '@zimbro-app/enums';
import { BaseError } from '@zimbro-app/util';
import { PostPaymentParams } from 'api/types';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

type PaymentFormContext = {
  isLoading: boolean;
  payment?: PostPaymentParams;
  requestError?: Error;
};

const PaymentFormContext = createContext<PaymentFormContext>({
  isLoading: true,
});

export const usePaymentFormContext = () => useContext(PaymentFormContext);

class PaymentError extends BaseError {}

export const PaymentFormContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();
  const [payment, setPayment] = useState<PostPaymentParams | undefined>(
    undefined,
  );

  const [params] = useSearchParams();

  useEffect(() => {
    if (payment || error || !params) {
      return;
    }

    try {
      console.assert(params.size, 'No payment parameters specified');
      console.assert(params.has('vendor'), 'Vendor is not specified');
      console.assert(params.has('orderId'), 'Order Id is not specified');
      console.assert(params.has('total'), 'Payment total is not specified');
      console.assert(params.has('token'), 'Payment currency is not specified');
      console.assert(params.has('onSuccess'), 'Please specify success URL');
      console.assert(params.has('onCancel'), 'Please specify cancel URL');
      console.assert(params.has('onReturn'), 'Please specify return URL');
    } catch (cause) {
      setError(new PaymentError('Missing payment parameters', { cause }));
      return;
    }

    const {
      vendor,
      orderId,
      total,
      token,
      onSuccess,
      onCancel,
      onReturn,
      ttl,
    } = Object.fromEntries(params.entries());

    setError(undefined);
    setPayment({
      vendor,
      orderId,
      total,
      token: token as Token,
      onSuccess,
      onCancel,
      onReturn,
      ttl: ttl ? parseInt(ttl) : undefined,
    });
  }, [params]);

  useEffect(() => {
    if (!payment) {
      return;
    }
    (async () => {
      try {
        const { paymentId } = await api.post.payment(payment);
        navigate(`/payment/${paymentId}`);
      } catch (cause) {
        setError(
          new PaymentError('Failed to create payment', {
            cause,
            ...payment,
          }),
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, [payment, navigate]);

  const value = {
    requestError: error,
    payment,
    isLoading,
  };

  return (
    <PaymentFormContext.Provider value={value}>
      {children}
    </PaymentFormContext.Provider>
  );
};
