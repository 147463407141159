import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';

export type SidebarProps = {
  title?: string;
  className?: string;
  side?: 'left' | 'right';
};

export enum SidebarSide {
  Left = 'left',
  Right = 'right',
}

export const Sidebar: FC<PropsWithChildren<SidebarProps>> & {
  Side: typeof SidebarSide;
} = ({ children, title, side = SidebarSide.Left, className }) => {
  return (
    <>
      <a
        href='#'
        data-uk-icon='menu'
        data-uk-toggle='target: #zimbro-sidebar; cls: uk-visible@s; mode: click; animation: uk-animation-slide-left; duration: 200;'
        className={cx(
          'uk-hidden@s',
          side === SidebarSide.Left ? 'uk-position-left' : 'uk-position-right',
        )}
      />
      <div
        id='zimbro-sidebar'
        className={cx(
          'uk-background-default',
          'uk-visible@s',
          // side === SidebarSide.Left ? 'uk-position-left' : 'uk-position-right',
          className,
        )}
      >
        <a
          href='#'
          data-uk-close
          data-uk-toggle='target: #zimbro-sidebar; cls: uk-visible@s; mode: click; animation: uk-animation-slide-left; duration: 200;'
          className={cx(
            'uk-hidden@s',
            'uk-position-top-right',
            'uk-position-absolute',
          )}
        />
        {title ? (
          <h2 className={cx('uk-h2', 'uk-margin-remove-top')}>{title}</h2>
        ) : null}
        <div>{children}</div>
      </div>
    </>
  );
};

Sidebar.Side = SidebarSide;

export default Sidebar;
