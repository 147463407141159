import { useState } from 'react';
import cx from 'classnames';
import Vendor from './Vendor';
import VendorList from './List';
import VendorForm from './Create';

enum View {
  List,
  Item,
  Form,
}

export default () => {
  const [view, setView] = useState<View>(View.List);
  const [item, setItem] = useState<string>('');

  const onItemClick = (id: string) => {
    setView(View.Item);
    setItem(id);
  };

  const backToList = () => {
    setView(View.List);
    setItem('');
  };

  const create = () => {
    setView(View.Form);
  };

  return (
    <div className={cx('uk-section-small')}>
      {view === View.List && (
        <VendorList onItemClick={onItemClick} onCreateClick={create} />
      )}
      {view === View.Item && (
        <Vendor onClose={backToList} onEdit={() => {}} vendorId={item} />
      )}
      {view === View.Form && (
        <VendorForm onSuccess={() => {}} onClose={backToList} />
      )}
    </div>
  );
};
