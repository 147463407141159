import cx from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Error: FC<{ title?: string; error: Error }> = ({ title, error }) => {
  const { t } = useTranslation();
  return (
    <div className={cx('uk-alert-danger', 'uk-padding')}>
      <h2 className={cx('uk-h2', 'uk-text-danger')}>
        {title || t('alert.error', 'Error')}
      </h2>
      <p className={cx('uk-text-danger')}>{error.message}</p>
      {Object.keys(error).length ? (
        <pre className={cx('uk-text-danger')}>
          {JSON.stringify(error, null, 4)}
        </pre>
      ) : null}
    </div>
  );
};

export default Error;
