import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { SignInProvider, useSignin } from './Context';
import { InputError } from '#components';

const Signin = () => {
  const { t } = useTranslation();
  const {
    login,
    password,
    handleSubmit,
    loginError,
    passwordError,
    authError,
    setLogin,
    setPassword,
    reset,
  } = useSignin();

  const isNotValid = !login || !password || !!(loginError || passwordError);

  return (
    <>
      <form onSubmit={e => e.preventDefault()} className={cx('zimbro-form')}>
        <div>
          <h2 className='uk-h2 uk-text-center'>{t('Welcome')}</h2>
          <p className='uk-text-center uk-text-muted'>
            {t('Please authenticate')}
          </p>
          <div
            className={cx(
              'uk-flex',
              'uk-flex-column',
              'uk-flex-center',
              'uk-width-1-3@m',
              'uk-width-1-1@s',
              'uk-margin-auto',
              'uk-padding-small',
            )}
          >
            <div
              className={cx('uk-margin-small-bottom', 'zimbro-form-input-box')}
            >
              <label
                htmlFor='sign-in-name-or-email'
                className={cx('uk-form-label', {
                  'uk-form-danger': !!loginError,
                })}
              >
                {t('form.email', 'Name or Email')}:
              </label>
              <input
                className={cx('uk-input', 'uk-border-pill')}
                type='text'
                id='sign-in-name-or-email'
                value={login}
                onChange={e => setLogin(e.target.value)}
                placeholder={t(
                  'signinForm.emailPlaceholder',
                  'Enter your name or email',
                )}
              />
              <InputError error={loginError?.message} />
            </div>
            <div
              className={cx('uk-margin-small-bottom', 'zimbro-form-input-box')}
            >
              <label htmlFor='sign-in-password' className={cx('uk-form-label')}>
                {t('form.password', 'Password')}:
              </label>
              <input
                className={cx('uk-input', 'uk-border-pill', {
                  'uk-form-danger': !!passwordError,
                })}
                id='sign-in-password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder={t(
                  'signinForm.passwordPlaceholder',
                  'Enter your password',
                )}
              />
              <InputError error={passwordError?.message} />
            </div>
            <div
              className={cx(
                'uk-flex',
                'uk-flex-center',
                'uk-margin-medium-top',
              )}
            >
              <a
                onClick={handleSubmit}
                className={cx(
                  'uk-button',
                  'uk-border-pill',
                  'uk-button-primary',
                  {
                    'zimbro-button-disabled': isNotValid,
                    'uk-text-muted': isNotValid,
                    'uk-disabled': isNotValid,
                  },
                )}
              >
                {t('signinForm.signInButton', 'Sign In')}
              </a>
            </div>
            {authError && (
              <div className={cx('uk-alert-danger')} data-uk-alert>
                <a
                  href='#'
                  className='uk-alert-close'
                  onClick={reset}
                  data-uk-close
                />
                <h4>{`${authError.name.replace(/([a-z])([A-Z])/g, '$1 $2') || 'Authentication error'}`}</h4>
                <p>{`${
                  (authError.statusCode && `${authError.statusCode} `) || ''
                }${(authError.error && `${authError.error}: `) || ''}${
                  authError.message
                }`}</p>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default () => {
  const { t } = useTranslation();

  return (
    <SignInProvider>
      <h1 className='uk-text-center uk-h1'>{t('signin.title', 'Sign In')}</h1>
      {/* <p className='uk-text-center uk-text-muted'>
        {t('signup.subtitle', 'Create your account')}
      </p> */}
      <Signin />
    </SignInProvider>
  );
};
