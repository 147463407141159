import cx from 'classnames';
import { useDashboardContext } from '#components/Dashboard/Context';
import { Tx } from 'api/types';
import { useTranslation } from 'react-i18next';
import { ellipsize } from '#util';

export default ({
  txHash,
  source,
  target,
  sourceAccount,
  targetAccount,
  sourceAccountName,
  targetAccountName,
  payment,
}: Tx & { payment?: string }) => {
  const { t } = useTranslation();
  const { account } = useDashboardContext();

  let purpose: string;
  let details: string;

  if (payment) {
    purpose = t('dashboard.tx.purpose.payment', 'Payment #') + payment;
    details = `${t('dashboard.tx.purpose.payer', 'Payer:')} ${
      (sourceAccountName && ellipsize(sourceAccountName, 40)) ||
      ellipsize.mid(source, 42)
    }`;
  } else if (sourceAccount === account.id) {
    if (targetAccount) {
      purpose = `${t('dashboard.tx.purpose.transfer-to', 'Transfer To:')} ${ellipsize(
        targetAccountName!,
        40,
      )}`;
      details = `${t('dashboard.tx.purpose.account-id', 'Account id:')} ${targetAccount}`;
    } else {
      purpose = t('dashboard.tx.purpose.withdrawal', 'Withdrawal');
      details = `${t('dashboard.tx.purpose.address-to', 'To:')} ${ellipsize.mid(
        target,
        40,
      )}`;
    }
  } else if (targetAccount === account.id) {
    if (sourceAccount) {
      purpose = `${t('dashboard.tx.purpose.transfer-from', 'Transfer From:')} ${ellipsize(
        sourceAccountName!,
        40,
      )}`;

      details = `${t('dashboard.tx.purpose.account-id', 'Account id:')} ${sourceAccount}`;
    } else {
      purpose = t('dashboard.tx.purpose.deposit', 'Deposit');
      details = `${t('dashboard.tx.purpose.address-from', 'From:')} ${ellipsize.mid(
        source,
        40,
      )}`;
    }
  } else {
    purpose = t('dashboard.tx.purpose.unknown');
    details = ellipsize.mid(txHash, 40);
  }

  return (
    <span className={cx('uk-flex', 'uk-flex-column')}>
      <span>{purpose}</span>
      <span className={cx('uk-text-muted', 'uk-text-small')}>{details}</span>
    </span>
  );
};
