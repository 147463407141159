import { useEffect, useState } from 'react';

export const useTimer = (limit?: Date) => {
  const [now, setNow] = useState<Date>(new Date());
  const disabled = limit ? now.getTime() > limit.getTime() : false;

  useEffect(() => {
    if (disabled) {
      return;
    }
    const interval = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(interval);
  }, [disabled]);

  return now;
};
