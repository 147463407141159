import { useTranslation } from 'react-i18next';
import { Page } from '#components';

export default () => {
  const { t } = useTranslation();
  return (
    <Page title={t('Home page')}>
      <p>Welcome to Zimbro!</p>
    </Page>
  );
};
