import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import api from '#api';
import { Vendor } from 'api/types';

type VendorContext = {
  error: Error | undefined;
  vendor: Vendor;
  isLoading: boolean;
};

const VendorContext = createContext<VendorContext>({
  isLoading: false,
  error: undefined,
  vendor: {
    vendorId: '',
    vendorName: '',
    origin: '',
    apiSecret: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
});

export const useVendorContext = () => useContext(VendorContext);

export type VendorProps = {
  vendorId: string;
};

export const VendorContextProvider = ({
  children,
  vendorId,
}: PropsWithChildren<VendorProps>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [vendor, setVendor] = useState<Vendor>({
    vendorId: '',
    vendorName: '',
    origin: '',
    apiSecret: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const data = await api.get.vendorId({ vendorId });
        setVendor(data);
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const value = { error, vendor, isLoading };

  return (
    <VendorContext.Provider value={value}>{children}</VendorContext.Provider>
  );
};
