import { useTranslation } from 'react-i18next';
import { PaymentFormContextProvider, usePaymentFormContext } from './Context';
import cx from 'classnames';
import Page from '#components/Page';

const PaymentForm = () => {
  const { t } = useTranslation();
  const { isLoading, requestError, payment } = usePaymentFormContext();

  return (
    <Page title={t('payment.page-title', 'Payment')}>
      {isLoading ? (
        <div data-uk-spinner='ratio: 2.25' />
      ) : requestError ? (
        <div className={cx('uk-alert-danger', 'uk-padding')}>
          <h2 className={cx('uk-h2', 'uk-text-danger')}>
            {t('payment.request.error', 'Failed to Create Payment')}
          </h2>
          <p className={cx('uk-text-danger')}>{requestError.message}</p>
          <pre className={cx('uk-text-danger')}>
            {JSON.stringify(requestError, null, 4)}
          </pre>
        </div>
      ) : (
        <div className={cx('uk-alert-success', 'uk-padding')}>
          {payment && <pre>{JSON.stringify(payment, null, 4)}</pre>}
        </div>
      )}
    </Page>
  );
};

export default () => (
  <PaymentFormContextProvider>
    <PaymentForm />
  </PaymentFormContextProvider>
);
