import cx from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { useSignup } from './Context';

const AccountAgreement = () => {
  const { t } = useTranslation();
  const { data, handleChange } = useSignup();
  return (
    <div>
      <h2 className='uk-h2 uk-text-center'>{t('Service agreement')}</h2>
      <p className='uk-text-center uk-text-muted'>
        {t('Please review and accept the following')}
      </p>
      <div
        data-uk-grid
        className={cx('uk-grid-small', 'uk-flex-center', 'uk-padding-small')}
      >
        <div className={cx('uk-width-2-5@m', 'uk-width-1-1@s')}>
          <p>
            <Trans ns={'user-agreement'}>
              To complete your registration, you need to agree to our{' '}
              <a href='/terms-and-conditions' target='_blank'>
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a href='/privacy-policy' target='_blank'>
                Privacy Policy
              </a>
              .
            </Trans>
          </p>
          <p>
            <Trans ns={'kyc-policy'}>
              Please be aware that your account is subject to, and must comply
              with, our{' '}
              <a href='/aml-kyc-policy' target='_blank'>
                AML/KYC Policy
              </a>{' '}
              and requires verification.
            </Trans>
          </p>
          <div
            className={cx(
              'uk-margin',
              'uk-grid-small',
              'uk-child-width-auto',
              'uk-grid',
            )}
          >
            <label>
              <input
                id='sign-up-terms-and-conditions'
                type='checkbox'
                className={cx('uk-checkbox', 'uk-margin-small-right')}
                checked={data.agreedToTerms}
                onChange={handleChange('agreedToTerms')}
              />
              {t(
                'terms.agreeTerms',
                'I agree to the Terms and Conditions and Privacy Policy.',
              )}
            </label>
            <label>
              <input
                id='sign-up-kyc-policy'
                type='checkbox'
                className={cx('uk-checkbox', 'uk-margin-small-right')}
                checked={data.agreedToAmlKyc}
                onChange={handleChange('agreedToAmlKyc')}
              />
              {t('terms.agreeAmlKyc', 'I agree with the AML/KYC Policy.')}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountAgreement;
