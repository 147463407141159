import cx from 'classnames';
import {
  useVendorContext,
  VendorContextProvider,
  VendorProps,
} from './Context';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { formatDatetime } from '#util';

type VendorViewProps = {
  className?: string;
  onClose: () => void;
  onEdit: () => void;
};

const Vendor = ({ className, onClose }: VendorViewProps) => {
  const { t } = useTranslation();
  const { vendor, isLoading, error } = useVendorContext();
  const [hideSecret, setHideSecret] = useState<boolean>(true);

  return (
    <>
      <div
        className={cx(
          'uk-flex',
          'uk-flex-middle',
          'uk-flex',
          'uk-flex-between',
          'uk-margin-bottom',
        )}
      >
        <h2 className={cx('uk-h2', 'uk-margin-remove')}>
          {t('dashboard.vendor.info.title', 'Vendor Details')}
        </h2>
        <div className={cx('uk-flex-shrink')}>
          <a
            href='#'
            className={cx('uk-link-muted', 'uk-margin-left')}
            title={t('dashboard.vendor.info.close', 'Back to list')}
            data-uk-icon='icon: close; ratio: 1.25'
            onClick={e => (e.preventDefault(), onClose())}
          />
        </div>
      </div>
      {isLoading ? (
        <div data-uk-spinner='ratio: 1' />
      ) : error ? (
        <div className={cx('uk-text-danger')}>
          {`${t('dashboard.vendor.error', 'Failed to load vendor data')}: ${
            error.message || error
          }`}
        </div>
      ) : (
        <dl className={cx('uk-description-list', className)}>
          <dt className={cx('uk-text-muted')}>
            {t('dashboard.vendor.info.id', 'Vendor ID')}
          </dt>
          <dd className={cx('uk-text-lead', 'uk-text-secondary')}>
            {vendor.vendorId}
          </dd>
          <dt className={cx('uk-text-muted')}>
            {t('dashboard.vendor.info.name', 'Vendor Name')}
          </dt>
          <dd className={cx('uk-text-lead', 'uk-text-secondary')}>
            {vendor.vendorName}
          </dd>
          <dt className={cx('uk-text-muted')}>
            {t('dashboard.vendor.info.full-name', 'Vendor Full Name')}
          </dt>
          <dd className={cx('uk-text-lead', 'uk-text-secondary')}>
            {vendor.fullName || '-'}
          </dd>
          <dt className={cx('uk-text-muted')}>
            {t('dashboard.vendor.info.origin', 'Website Address')}
          </dt>
          <dd>
            <a className={cx('uk-text-lead')}>{vendor.origin}</a>
          </dd>
          <dt className={cx('uk-text-muted', 'uk-flex', 'uk-flex-middle')}>
            <span>{t('dashboard.vendor.info.api-key', 'API Key')}</span>
            <a
              href='#'
              className={cx('uk-margin-small-left')}
              title={
                hideSecret
                  ? t('dashboard.vendor.info.secret.reveal', 'Reveal Secret')
                  : t('dashboard.vendor.info.secret.hide', 'Hide Secret')
              }
              onClick={e => (e.preventDefault(), setHideSecret(hide => !hide))}
              data-uk-icon={`icon: ${
                hideSecret ? 'eye' : 'eye-slash'
              }; ratio: 1`}
            />
          </dt>
          <dd className={cx('uk-text-lead', 'uk-text-secondary')}>
            {hideSecret ? '***' : vendor.apiSecret}
          </dd>
          <dt className={cx('uk-text-muted')}>
            {t('dashboard.vendor.info.description', 'Description')}
          </dt>
          <dd className={cx('uk-text-lead', 'uk-text-secondary')}>
            {vendor.description || '-'}
          </dd>
          <dt className={cx('uk-text-muted')}>
            {t('dashboard.vendor.info.created', 'Created At')}
          </dt>
          <dd className={cx('uk-text-lead', 'uk-text-secondary')}>
            {formatDatetime(vendor.createdAt)}
          </dd>
          <dt className={cx('uk-text-muted')}>
            {t('dashboard.vendor.info.updated', 'Last Modified')}
          </dt>
          <dd className={cx('uk-text-lead', 'uk-text-secondary')}>
            {formatDatetime(vendor.updatedAt)}
          </dd>
        </dl>
      )}
    </>
  );
};

export default ({ vendorId, ...props }: VendorProps & VendorViewProps) => (
  <VendorContextProvider vendorId={vendorId}>
    <Vendor {...props} />
  </VendorContextProvider>
);
