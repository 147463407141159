import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import api from '#api';
import { useTranslation } from 'react-i18next';
import { isValidUrl } from '#util';

type VendorFormContext = {
  error?: string;
  isLoading: boolean;
  vendorName: string;
  fullName: string;
  origin: string;
  description: string;
  vendorNameError?: string;
  fullNameError?: string;
  originError?: string;
  descriptionError?: string;
  setVendorName: (name: string) => void;
  setFullName: (name: string) => void;
  setOrigin: (origin: string) => void;
  setDescription: (description: string) => void;
  reset: () => void;
  submit: () => Promise<void>;
};

const VendorFormContext = createContext<VendorFormContext>({
  isLoading: false,
  error: undefined,
  vendorName: '',
  fullName: '',
  origin: '',
  description: '',
  vendorNameError: undefined,
  fullNameError: undefined,
  originError: undefined,
  descriptionError: undefined,
  setVendorName: () => {},
  setFullName: () => {},
  setOrigin: () => {},
  setDescription: () => {},
  reset: () => {},
  submit: () => Promise.resolve(),
});

export const useVendorFormContext = () => useContext(VendorFormContext);

export type VendorFormProps = { onClose: () => void; onSuccess: () => void };

export const VendorFormContextProvider = ({
  children,
  onClose,
}: PropsWithChildren<VendorFormProps>) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [vendorName, setVendorName] = useState<string | undefined>(undefined);
  const [fullName, setFullName] = useState<string | undefined>(undefined);
  const [origin, setOrigin] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [vendorNameError, setVendorNameError] = useState<string | undefined>(
    undefined,
  );
  const [fullNameError, setFullNameError] = useState<string | undefined>(
    undefined,
  );
  const [originError, setOriginError] = useState<string | undefined>(undefined);
  const [descriptionError, setDescriptionError] = useState<string | undefined>(
    undefined,
  );

  const reset = () => {
    setError(undefined);
    setVendorName(undefined);
    setFullName(undefined);
    setOrigin(undefined);
    setDescription(undefined);
    setVendorNameError(undefined);
    setFullNameError(undefined);
    setOriginError(undefined);
    setDescriptionError(undefined);
  };

  useEffect(() => {
    if (vendorName === undefined) {
      return;
    } else if (vendorName.length > 32) {
      setVendorNameError(
        t('dashboard.vendor.form.name.too-long', 'Vendor name is too long'),
      );
    } else if (vendorName === '') {
      setVendorNameError(
        t('dashboard.vendor.form.name.required', 'Vendor name is mandatory'),
      );
    } else if (vendorNameError) {
      setVendorNameError(undefined);
    }
  }, [vendorName, vendorNameError, t]);

  useEffect(() => {
    if (fullName === undefined) {
      return;
    } else if (fullName.length > 128) {
      setFullNameError(
        t('dashboard.vendor.form.full-name.too-long', 'Full name is too long'),
      );
    } else if (fullNameError) {
      setFullNameError(undefined);
    }
  }, [fullName, fullNameError, t]);

  useEffect(() => {
    if (origin === undefined) {
      return;
    } else if (origin.length > 256) {
      setOriginError(
        t(
          'dashboard.vendor.form.origin.too-long',
          'Website address is too long',
        ),
      );
    } else if (origin === '') {
      setOriginError(
        t(
          'dashboard.vendor.form.origin.required',
          'Website address is mandatory',
        ),
      );
    } else if (!isValidUrl(origin)) {
      setOriginError(
        t(
          'dashboard.vendor.form.origin.invalid',
          'Must be a valid website address',
        ),
      );
    } else if (originError) {
      setOriginError(undefined);
    }
  }, [origin, originError, t]);

  useEffect(() => {
    if (description === undefined) {
      return;
    } else if (description.length > 1024) {
      setDescriptionError(
        t(
          'dashboard.vendor.form.description.too-long',
          'Description length should be less than 1024 characters',
        ),
      );
    } else if (descriptionError) {
      setDescriptionError(undefined);
    }
  }, [description, descriptionError, t]);

  const submit = async () => {
    if (
      vendorNameError ||
      fullNameError ||
      originError ||
      descriptionError ||
      !vendorName ||
      !origin
    ) {
      if (vendorName === undefined) {
        setVendorName('');
      }
      if (origin === undefined) {
        setOrigin('');
      }
      setError(t('dashboard.vendor.form.error', 'Please fix the errors'));
      return;
    }
    setIsLoading(true);
    try {
      await api.post.vendor({
        vendorName,
        fullName,
        origin,
        description,
      });
      onClose();
    } catch (error) {
      setError((error as Error).message || (error as string));
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    error,
    isLoading,
    vendorName: vendorName || '',
    fullName: fullName || '',
    origin: origin || '',
    description: description || '',
    vendorNameError,
    fullNameError,
    originError,
    descriptionError,
    setVendorName,
    setFullName,
    setOrigin,
    setDescription,
    reset,
    submit,
  };

  return (
    <VendorFormContext.Provider value={value}>
      {children}
    </VendorFormContext.Provider>
  );
};
