import { useEffect, useState } from 'react';

export const useTransition = (isMounted: boolean, unmountDelay: number) => {
  const [finishedTransition, setFinishedTransition] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isMounted && !finishedTransition) {
      setFinishedTransition(true);
    } else if (!isMounted && finishedTransition) {
      timeoutId = setTimeout(() => setFinishedTransition(false), unmountDelay);
    }

    return () => clearTimeout(timeoutId);
  }, [unmountDelay, isMounted, finishedTransition]);

  return finishedTransition;
};
