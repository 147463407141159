import cx from 'classnames';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { ErrorView, LoadIndicator } from '#components';
import { PaymentFormContextProvider, usePaymentFormContext } from './Context';

import '../../App/index.less';

const PaymentForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const {
    isLoading,
    error,
    vendors,
    vendor,
    total,
    ttl,
    changeTotal,
    changeTtl,
    submit,
    setVendor,
  } = usePaymentFormContext();

  const isValid = total && total > 0 && ttl && ttl > 0 && vendor;

  return (
    <>
      <div
        className={cx(
          'uk-flex',
          'uk-flex-middle',
          'uk-flex',
          'uk-flex-between',
          'uk-margin-bottom',
        )}
      >
        <h2 className={cx('uk-h2', 'uk-margin-remove')}>
          {t('payment.form.title', 'Create Payment')}
        </h2>
        <div className={cx('uk-flex-shrink')}>
          <a
            href='#'
            className={cx('uk-link-muted', 'uk-margin-left')}
            title={t('payment.form.back', 'Back to list')}
            data-uk-icon='icon: close; ratio: 1.25'
            onClick={e => (e.preventDefault(), onClose())}
          />
        </div>
      </div>
      {isLoading ? (
        <LoadIndicator ratio={4} />
      ) : error || !vendors.length ? (
        <ErrorView
          error={error || new Error('No vendors, please create a vendor first')}
        />
      ) : (
        <form
          className={cx(
            'zimbro-form',
            'uk-flex',
            'uk-flex-column',
            'uk-width-1-2@m',
            'uk-width-1-1@s',
          )}
          onSubmit={e => e.preventDefault()}
        >
          <div className={cx()}>
            <label htmlFor='total' className={cx('uk-form-label')}>
              Choose one of your vendors:
            </label>
            <Select
              inputId='paymentToken'
              placeholder={t('payment.form.vendor.select', 'Select Vendor...')}
              classNames={{
                control: () => cx('z-select', 'uk-margin-bottom'),
              }}
              value={
                vendor
                  ? { value: vendor.vendorId, label: vendor.vendorName }
                  : null
              }
              onChange={o =>
                setVendor(
                  o ? vendors.find(v => v.vendorId === o.value) : undefined,
                )
              }
              options={vendors.map(v => ({
                value: v.vendorId,
                label: v.vendorName,
              }))}
            />
          </div>
          <div className={cx()}>
            <label htmlFor='total' className={cx('uk-form-label')}>
              Payment amount, USD:
            </label>
            <input
              type='number'
              step='0.01'
              value={total}
              onChange={e => (e.preventDefault(), changeTotal(e.target.value))}
              placeholder='Payment amount'
              className={cx(
                'uk-input',
                'uk-border-rounded',
                'uk-margin-bottom',
              )}
            />
          </div>
          <div className={cx()}>
            <label htmlFor='total' className={cx('uk-form-label')}>
              Payment expires in, seconds:
            </label>
            <input
              type='number'
              value={ttl}
              onChange={e => (e.preventDefault(), changeTtl(e.target.value))}
              placeholder='Payment expires in'
              className={cx(
                'uk-input',
                'uk-border-rounded',
                'uk-margin-bottom',
              )}
            />
          </div>
          <div className={cx('uk-margin-medium-top')}>
            <a
              href='#'
              onClick={e => (e.preventDefault(), submit())}
              className={cx(
                'uk-button',
                'uk-button-primary',
                'uk-border-pill',
                {
                  'zimbro-button-disabled': !isValid,
                  'uk-text-muted': !isValid,
                  'uk-disabled': !isValid,
                },
              )}
            >
              {t('payment.form.submit', 'Create Payment')}
            </a>
          </div>
        </form>
      )}
    </>
  );
};

export default ({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: (paymentId: string) => void;
}) => (
  <PaymentFormContextProvider onSuccess={onSuccess}>
    <PaymentForm onClose={onClose} />
  </PaymentFormContextProvider>
);
